import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { UploadFiles } from '../../../domain/upload-files';
import { getUploadFilesList } from '../../../helpers/upload';
import {
  fromYearMonthState,
  toYearMonthState,
} from '../../../recoil/atoms/upload-files';
import { RoleEnum, UploadFileTypeEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import UploadFilesListSearch from './ListSearch';
import UploadFilesTable from './ListTable';

interface Props {
  fileType: UploadFileTypeEnum;
}

function UploadedFiles({ fileType }: Props) {
  const [showErrorNotification] = useErrorNotification();
  const fromYearMonth = useRecoilValue(fromYearMonthState);
  const toYearMonth = useRecoilValue(toYearMonthState);
  const [uploadFilesList, setUploadFilesList] = useState<UploadFiles[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const searchHeight = showSearch ? 94 : 46;

  const refreshUploadFiles = async () => {
    setLoading(true);
    try {
      const filesList = await getUploadFilesList(
        fileType,
        dayjs(fromYearMonth).startOf('month').format('YYYY-MM-DD'),
        dayjs(toYearMonth).endOf('month').format('YYYY-MM-DD')
      );
      setUploadFilesList(filesList);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useCheckUserPermission([RoleEnum.Admin]);
  useEffect(() => {
    (async () => {
      await refreshUploadFiles();
    })();
  }, [fromYearMonth, toYearMonth]);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  const handleFileListRefresh = async () => {
    await refreshUploadFiles();
  };

  return (
    <>
      <UploadFilesListSearch
        onResize={handleSearchResize}
        onRefresh={handleFileListRefresh}
      />
      <UploadFilesTable
        uploadFilesList={uploadFilesList}
        searchHeight={searchHeight}
        loading={loading}
      />
    </>
  );
}

export default UploadedFiles;
