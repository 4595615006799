import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { SYSTEM_HIGHT } from '../../../constants/common';
import { PersonalDetailOutput } from '../../../domain/man-hours';
import { getPersonalDetail } from '../../../helpers/man-hours';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import ManHoursDetailSearch from './ManHoursDetailSearch';
import ManHoursDetailTable from './ManHoursDetailTable';

function ManHoursDetails() {
  const [params] = useSearchParams();
  const [, , employeeId] = useLocation().pathname.split('/');
  const [showErrorNotification] = useErrorNotification();
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);
  const month = params.get('month');
  const year = params.get('year');
  const [employeeName, setEmployeeName] = useState<string>();
  const [personalMhList, setPersonalMhList] = useState<PersonalDetailOutput[]>(
    []
  );
  const [showSearch, setShowSearch] = useState(true);
  const [loading, setLoading] = useState(false);
  const searchHeight = showSearch ? 134 + SYSTEM_HIGHT.BREADCRUMBS : 58;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (employeeId == null || year == null || month == null)
          throw new Error('SearchValue is not found');
        const personalMhListValue = await getPersonalDetail(
          employeeId,
          Number(year),
          Number(month)
        );
        setPersonalMhList(personalMhListValue);
        setEmployeeName(
          personalMhListValue.length ? personalMhListValue[0].empName ?? '' : ''
        );
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  return (
    <>
      {employeeId != null &&
        employeeName != null &&
        month != null &&
        year != null && (
          <ManHoursDetailSearch
            onResize={handleSearchResize}
            employeeCode={employeeId}
            employeeName={employeeName}
            year={year}
            month={month}
          />
        )}
      <ManHoursDetailTable
        personalMhList={personalMhList}
        searchHeight={searchHeight + SYSTEM_HIGHT.BREADCRUMBS}
        loading={loading}
      />
    </>
  );
}

export default ManHoursDetails;
