import camelcaseKeys from 'camelcase-keys';

import { Members } from '../domain/members';
import { api } from '../lib';

const getMembers = () =>
  api.membersApi.getMembersList().then((res) => {
    const results = camelcaseKeys(res.data, { deep: true });
    return results as Members[];
  });

export { getMembers };
