import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import useBreadcrumbs, {
  createRoutesFromChildren,
} from 'use-react-router-breadcrumbs';

import classes from '../../styles/common.module.scss';

import { generateAppRoute } from './Main';

export function Breadcrumbs() {
  const routers = generateAppRoute();
  const breadcrumbs = useBreadcrumbs(createRoutesFromChildren(routers), {
    excludePaths: [
      '/',
      'manage-cost',
      '/man-hours-total/projectsDetail',
      '/man-hours-total/manHoursDetail',
      '/upload/bp-work',
      '/upload/account',
    ],
  });
  return breadcrumbs.length >= 2 ? (
    <Breadcrumb
      className={classes.breadCrumbLayout}
      items={breadcrumbs.map(({ breadcrumb, match }, index) => ({
        title:
          breadcrumbs.length !== index + 1 ? (
            <Link to={match.pathname}>{breadcrumb}</Link>
          ) : (
            <span>{breadcrumb}</span>
          ),
      }))}
    />
  ) : (
    <div />
  );
}
