import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { ProjectList } from '../../../domain/cost-expense';
import { table, uniqueByColumn } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { Table } from '../../common';
import { getColumnSearchProps } from '../../common/TableFilter';
import { TableNumberFilterProps } from '../../common/TableNumberFilter';

const TABLE_HEADER_HEIGHT = 62;

interface Props {
  costExpenseList: ProjectList[];
  searchHeight: number;
  loading?: boolean;
}

export default function CostExpenseListTable({
  costExpenseList,
  searchHeight,
  loading,
}: Props) {
  const columns: ColumnsType<ProjectList> = [
    {
      title: 'PJ番号',
      dataIndex: 'projectCode',
      width: 100,
      fixed: 'left',
      sorter: (a, b) => table.descendingComparator(a, b, 'projectCode'),
      ...getColumnSearchProps(
        'projectCode',
        'PJ番号',
        uniqueByColumn(costExpenseList, 'projectCode').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: 'JOBNo',
      dataIndex: 'jobNo',
      defaultSortOrder: 'descend',
      width: 100,
      fixed: 'left',
      render: (text, { jobNo }) => {
        return <Link to={`/cost-expense/${jobNo}`}>{text}</Link>;
      },
      sorter: {
        compare: (a, b) => table.descendingComparator(a, b, 'jobNo'),
        multiple: 1,
      },
      ...getColumnSearchProps(
        'jobNo',
        'JOBNo',
        uniqueByColumn(costExpenseList, 'jobNo').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: '案件名',
      dataIndex: 'projectTitle',
      width: 300,
      fixed: 'left',
      ...getColumnSearchProps(
        'projectTitle',
        '案件名',
        uniqueByColumn(costExpenseList, 'projectTitle').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectTitle'),
    },
    {
      title: 'PM/PL',
      dataIndex: 'projectManagerName',
      fixed: 'left',
      width: 110,
      ...getColumnSearchProps(
        'projectManagerName',
        'PM/PL',
        uniqueByColumn(costExpenseList, 'projectManagerName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectManagerName'),
    },
    {
      title: '担当者名',
      dataIndex: 'projectRepName',
      width: 110,
      ...getColumnSearchProps(
        'projectRepName',
        '担当者名',
        uniqueByColumn(costExpenseList, 'projectRepName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectRepName'),
    },
    {
      title: '部門名',
      dataIndex: 'deptName',
      width: 110,
      ...getColumnSearchProps(
        'deptName',
        '部門名',
        uniqueByColumn(costExpenseList, 'deptName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'deptName'),
    },
    {
      title: '売上\n計上額(円)',
      dataIndex: 'salesAmnt',
      key: 'salesAmnt',
      width: 120,
      align: 'right',
      ...TableNumberFilterProps('salesAmnt'),
      sorter: (a, b) => table.descendingComparator(a, b, 'salesAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '原価\n合計(円)',
      dataIndex: 'costAmnt',
      key: 'costAmnt',
      width: 110,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'costAmnt'),
      ...TableNumberFilterProps('costAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '粗利率',
      dataIndex: 'grossMarginRate',
      key: 'grossMarginRate',
      width: 90,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'grossMarginRate'),
      ...TableNumberFilterProps('grossMarginRate'),
      render: (value: number) => `${value ?? '0.00'} %`,
      onCell: ({ grossMarginRate }) => ({
        className: (grossMarginRate ?? 0) < 20 ? classes.dangerColor : '',
      }),
    },
    {
      title: '原価\n予測(円)',
      dataIndex: 'forecastCostAmnt',
      key: 'forecastCostAmnt',
      width: 110,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'forecastCostAmnt'),
      ...TableNumberFilterProps('forecastCostAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '粗利\n予測率',
      dataIndex: 'forecastGrossMarginRate',
      key: 'forecastGrossMarginRate',
      width: 100,
      align: 'right',
      sorter: (a, b) =>
        table.descendingComparator(a, b, 'forecastGrossMarginRate'),
      ...TableNumberFilterProps('forecastGrossMarginRate'),
      render: (value: number) => `${value ?? '0.00'} %`,
      onCell: ({ forecastGrossMarginRate }) => ({
        className:
          (forecastGrossMarginRate ?? 0) < 20 ? classes.dangerColor : '',
      }),
    },
    {
      title: '予定工数\n(時間)',
      dataIndex: 'planManHour',
      key: 'planManHour',
      width: 110,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
      ...TableNumberFilterProps('planManHour'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '実績工数\n(時間)',
      dataIndex: 'manHour',
      key: 'manHour',
      width: 110,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
      ...TableNumberFilterProps('manHour'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '工数\n進捗率',
      dataIndex: 'manHourProgressRate',
      key: 'manHourProgressRate',
      width: 100,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'manHourProgressRate'),
      ...TableNumberFilterProps('manHourProgressRate'),
      onCell: ({ manHourProgressRate }) => ({
        className: (manHourProgressRate ?? 0) > 100 ? classes.dangerColor : '',
      }),
      render: (value: number) => `${value ?? '0.00'} %`,
    },
    {
      title: '工数予測\n(時間)',
      dataIndex: 'forecastManHour',
      key: 'forecastManHour',
      width: 110,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'forecastManHour'),
      ...TableNumberFilterProps('forecastManHour'),
      onCell: ({ forecastManHour, planManHour }) => ({
        className:
          (forecastManHour ?? 0) > (planManHour ?? 0)
            ? classes.dangerColor
            : '',
      }),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '工数消化率\n(予測)',
      dataIndex: 'forecastManHourUseRate',
      key: 'forecastManHourUseRate',
      width: 125,
      align: 'right',
      sorter: (a, b) =>
        table.descendingComparator(a, b, 'forecastManHourUseRate'),
      ...TableNumberFilterProps('forecastManHourUseRate'),
      onCell: ({ forecastManHourUseRate }) => ({
        className:
          (forecastManHourUseRate ?? 0) > 100 ? classes.dangerColor : '',
      }),
      render: (value: number) => `${value ?? '0.00'} %`,
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      key: 'updateDate',
      width: 100,
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  return (
    <Table<ProjectList>
      rowKey="jobNo"
      dataSource={costExpenseList}
      columns={columns}
      loading={loading}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
    />
  );
}
