import { Form, DatePicker, Col, Row } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useForm from 'antd/es/form/hooks/useForm';
import { useRecoilState } from 'recoil';

import {
  fromYearMonthState,
  toYearMonthState,
} from '../../../recoil/atoms/cost-expense';
import classes from '../../../styles/search.module.scss';
import { Search } from '../../common';

interface Props {
  onResize: () => void;
}

export default function CostExpenseListSearch({ onResize }: Props) {
  const [form] = useForm();
  const fromYearMonthInput = useWatch('fromYearMonth', form);
  const toYearMonthInput = useWatch('toYearMonth', form);
  const [fromYearMonth, setFromYearMonth] = useRecoilState(fromYearMonthState);
  const [toYearMonth, setToYearMonth] = useRecoilState(toYearMonthState);

  const handleSearch = () => {
    if (form.isFieldsTouched()) {
      setFromYearMonth(fromYearMonthInput);
      setToYearMonth(toYearMonthInput);
    }
  };

  return (
    <Search form={form} onSearch={handleSearch} onResize={onResize}>
      <Row>
        <Col>
          <Form.Item
            name="fromYearMonth"
            label="開始年月"
            rules={[{ required: true }]}
            initialValue={fromYearMonth}
            className={classes.searchFormItem}
          >
            <DatePicker placeholder="YYYY/MM" picker="month" format="YYYY/MM" />
          </Form.Item>
        </Col>
        <Col style={{ paddingLeft: 12 }}>
          <Form.Item
            name="toYearMonth"
            label="終了年月"
            rules={[{ required: true }]}
            initialValue={toYearMonth}
            className={classes.searchFormItem}
          >
            <DatePicker placeholder="YYYY/MM" picker="month" format="YYYY/MM" />
          </Form.Item>
        </Col>
      </Row>
    </Search>
  );
}
