/* eslint-disable jsx-a11y/no-static-element-interactions */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, InputRef, Space } from 'antd';
import {
  ColumnFilterItem,
  ColumnType,
  FilterDropdownProps,
} from 'antd/es/table/interface';
import { useRef } from 'react';

import classes from '../../styles/search.module.scss';

interface Props extends FilterDropdownProps {
  indexName: string;
}

export function TableFilter({
  indexName,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
}: Props) {
  const searchInput = useRef<InputRef>(null);

  const handleSearch = () => confirm();

  return (
    <div
      style={{ padding: 8 }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <Input
        ref={searchInput}
        placeholder={`${indexName}を検索`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch()}
        className={classes.searchFilter}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          検索
        </Button>
        <Button
          onClick={() => {
            if (clearFilters === undefined) return;
            clearFilters();
            handleSearch();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          閉じる
        </Button>
      </Space>
    </div>
  );
}

export function getColumnSearchProps<T>(
  dataIndex: keyof T,
  indexName: string,
  filters: ColumnFilterItem[] = []
): ColumnType<T> {
  return {
    filterDropdown:
      filters.length > 0
        ? undefined
        : (filterOptions) => (
            <TableFilter indexName={indexName} {...filterOptions} />
          ),
    title: indexName,
    onFilter: (value, record) => {
      const data = String(record[dataIndex]);
      if (value === '') return data === '';

      return data.toLowerCase().includes((value as string).toLowerCase());
    },
    filters,
    filterSearch: filters.length > 0,
  };
}
