import camelcaseKeys from 'camelcase-keys';

import {
  PersonalSummaries,
  PersonalSummaryOutput,
  PersonalProject,
  PersonalDetailOutput,
} from '../domain/man-hours';
import { api } from '../lib';

const getPersonalDetail = (empCode: string, year: number, month: number) =>
  api.personalApi.getPersonalDetail(empCode, year, month).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true }).map(
      (personalDetail, index) => ({
        ...personalDetail,
        updateDate: new Date(personalDetail.updateDate).toLocaleString(),
        id: index,
      })
    );
    return results as PersonalDetailOutput[];
  });

const getPersonalProject = (empCode: string, year: number, month: number) =>
  api.personalApi.getPersonalProject(empCode, year, month).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true }).map(
      (personalDetail) => ({
        ...personalDetail,
        updateDate: new Date(personalDetail.updateDate).toLocaleString(),
      })
    );
    return results as PersonalProject[];
  });

const getPersonalSummary = (empCode: string, year: number, month?: number) =>
  api.personalApi.getPersonalSummary(empCode, year, month).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true }).map(
      (personalDetail) => {
        const { workYear, workMonth, updateDate } = personalDetail;
        const monthStr = workMonth.toString().padStart(2, '0');

        return {
          ...personalDetail,
          updateDate: new Date(updateDate).toLocaleString(),
          yearMonth: `${workYear}/${monthStr}`,
        };
      }
    );
    return results as PersonalSummaryOutput[];
  });

const getPersonalSummaries = (year: number, month: number) =>
  api.personalApi.getPersonalSummaries(year, month).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true }).map(
      (personalSummaries) => {
        const { updateDate } = personalSummaries;
        return {
          ...personalSummaries,
          updateDate: new Date(updateDate).toLocaleString(),
        };
      }
    );
    return results as PersonalSummaries[];
  });

export {
  getPersonalProject,
  getPersonalDetail,
  getPersonalSummary,
  getPersonalSummaries,
};
