import {
  Button,
  ButtonProps,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useState } from 'react';

import { Member, SubmitLoginUserInfoInput } from '../../../domain/users';
import { addMember, editMember } from '../../../helpers/users';
import classes from '../../../styles/search.module.scss';
import { useErrorNotification } from '../../hooks/error-notification';

interface Props {
  title: string;
  okButtonProps: ButtonProps;
  userInfo?: Member;
  onSubmit: (user: Member[]) => void;
}

export default function UserEditModal({
  title,
  onSubmit,
  okButtonProps,
  userInfo,
}: Props) {
  const [form] = useForm();
  const [showErrorNotification] = useErrorNotification();
  const isNew = userInfo === undefined;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { submit } = form;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (data: SubmitLoginUserInfoInput) => {
    setLoading(true);
    try {
      const res = isNew
        ? await addMember(data)
        : await editMember({
            ...data,
            empCode: userInfo.empCode,
            leaveDate: data.leaveDate?.format('YYYY-MM-DD'),
          });
      onSubmit(res);
      setIsModalOpen(false);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button {...okButtonProps} onClick={showModal}>
        {title}
      </Button>
      <Modal
        title={title}
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={() => submit()}
        okText="登録"
        destroyOnClose
        confirmLoading={loading}
      >
        <Form<SubmitLoginUserInfoInput>
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          preserve={false}
        >
          <Form.Item
            label="社員No"
            name="empCode"
            rules={[{ required: true }, { len: 4 }]}
            initialValue={userInfo?.empCode}
          >
            <Input
              className={classes.inputEmployeeNo}
              placeholder="社員Noを入力"
              disabled={isNew === false}
            />
          </Form.Item>
          <Form.Item
            label="氏名"
            name="empName"
            rules={[{ required: true }]}
            initialValue={userInfo?.empName}
          >
            <Input className={classes.inputUserName} placeholder="氏名を入力" />
          </Form.Item>
          <Form.Item
            label="メールアドレス"
            name="email"
            rules={[{ required: true }, { type: 'email' }]}
            initialValue={userInfo?.email}
          >
            <Input
              placeholder="メールアドレスを入力"
              className={classes.inputEmailAddress}
            />
          </Form.Item>
          <Form.Item
            label="退職日"
            name="leaveDate"
            initialValue={
              userInfo?.leaveDate != null ? dayjs(userInfo.leaveDate) : null
            }
          >
            <DatePicker format="YYYY/MM/DD" />
          </Form.Item>
          <Form.Item label="ロール" name="role" initialValue={userInfo?.role}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="admin">管理者</Radio.Button>
              <Radio.Button value="head">本部</Radio.Button>
              <Radio.Button value="normal">一般</Radio.Button>
              <Radio.Button value="no-permission">権限無し</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
