import { Button, Form, Input, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { CognitoUserInfo, SignInInput } from '../../../domain/auth';
import { signIn } from '../../../helpers/auth';
import { APIError } from '../../../lib';
import { sessionAtom } from '../../../recoil/atoms/auth';
import classes from '../../../styles/auth.module.scss';

import { NewPassword } from './NewPassword';

const { Text } = Typography;

export function SignIn() {
  const navigate = useNavigate();
  const setSession = useSetRecoilState(sessionAtom);
  const [cognitoUser, setCognitoUser] = useState<CognitoUserInfo>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const finish = async (value: SignInInput) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const user = await signIn(value.username, value.password);
      if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(user);
        return;
      }
      const session = (await Auth.currentSession()).getIdToken();
      setSession(session);
      navigate('/');
    } catch (err) {
      if (err instanceof Error)
        setErrorMessage(
          err instanceof APIError ? err.errorMessage : err.message
        );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    setCognitoUser(undefined);
  };

  return cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED' ? (
    <NewPassword user={cognitoUser} clear={clear} />
  ) : (
    <Form<SignInInput>
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={finish}
      validateTrigger="onSubmit"
    >
      <h1>収支管理システム</h1>
      <Form.Item
        label="メールアドレス"
        name="username"
        rules={[
          { required: true, message: 'メールアドレスを入力してください' },
          {
            type: 'email',
            message: '入力されたメールアドレスは有効ではありません',
          },
        ]}
      >
        <Input className={classes.emailAddress} />
      </Form.Item>
      <Form.Item
        label="パスワード"
        name="password"
        rules={[{ required: true, message: 'パスワードを入力してください' }]}
      >
        <Input.Password className={classes.password} />
      </Form.Item>
      {errorMessage && (
        <div className={classes.message}>
          <Text type="danger">{errorMessage}</Text>
        </div>
      )}
      <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          ログイン
        </Button>
      </Form.Item>
      <div className={classes.message}>
        <Button type="link" onClick={() => navigate('/forgot-password')}>
          パスワードを忘れた場合はこちら
        </Button>
      </div>
    </Form>
  );
}
