import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import classes from '../../../styles/search.module.scss';
import { Search } from '../../common';

interface Props {
  jobNo: string;
  projectTitle: string;
  onResize: () => void;
}
export default function CostExpenseDetailSearch({
  jobNo,
  projectTitle,
  onResize,
}: Props) {
  const [form] = useForm();
  return (
    <Search form={form} onResize={onResize}>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <Form.Item
            label="JOBNo"
            name="jobNo"
            className={classes.searchFormItemLabel}
          >
            <p>{jobNo}</p>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <Form.Item
            label="案件名"
            name="projectTitle"
            className={classes.searchFormItemLabel}
          >
            <p>{projectTitle}</p>
          </Form.Item>
        </Col>
      </Row>
    </Search>
  );
}
