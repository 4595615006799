import camelcaseKeys from 'camelcase-keys';

import {
  ProjectList,
  ProjectDetail,
  ProjectDetailOutput,
  ProjectMonthlyDetail,
} from '../domain/cost-expense';
import { api } from '../lib';

const getProjectList = (fromDate: Date, toDate: Date) =>
  api.projectApi
    .getProjectList(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      toDate.getFullYear(),
      toDate.getMonth() + 1
    )
    .then((res) => {
      const projectList = camelcaseKeys(res.data, { deep: true }).map(
        (project) => ({
          ...project,
          updateDate: new Date(project.updateDate).toLocaleString(),
        })
      );
      return projectList as ProjectList[];
    });

const getProjectDetail = (jobNo: string): Promise<ProjectDetailOutput[]> =>
  api.projectApi.getProjectDetail(jobNo).then((res) => {
    const bopDetail = camelcaseKeys(res.data, { deep: true });
    return (bopDetail as ProjectDetail[]).map((detail) => ({
      ...detail,
      updateDate: new Date(detail.updateDate).toLocaleString(),
      yearMonth: `${detail.workYear}${detail.workMonth
        .toString()
        .padStart(2, '0')}`,
    }));
  });

const getMonthDetail = async (jobNo: string, yearMonth: string) => {
  if (jobNo == null || /^[0-9]+$/.test(jobNo) === false)
    throw new Error('jobNo is not found or not numeric');

  if (yearMonth == null || /^[0-9]{6}/.test(yearMonth) === false)
    throw new Error('yearMonth is not found or not numeric');

  return api.projectApi
    .getProjectMonthlyDetail(
      jobNo,
      Number(yearMonth.substring(0, 4)),
      Number(yearMonth.substring(4, 6))
    )
    .then((res) => {
      const projectMonthlyDetail = camelcaseKeys(res.data, { deep: true });
      return (projectMonthlyDetail as ProjectMonthlyDetail[]).map((detail) => ({
        ...detail,
        updateDate: new Date(detail.updateDate).toLocaleString(),
      }));
    });
};

export { getProjectList, getProjectDetail, getMonthDetail };
