import { Button, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordSubmitInput } from '../../../domain/auth';
import { confirmForgotPassword } from '../../../helpers/auth';
import { APIError } from '../../../lib';
import classes from '../../../styles/auth.module.scss';

const { Text } = Typography;

interface Props {
  mailAddress: string;
}

export function ForgotPasswordSubmit({ mailAddress }: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const finish = async ({
    code,
    password,
    confirmPassword,
  }: ForgotPasswordSubmitInput) => {
    setErrorMessage('');
    if (password !== confirmPassword) {
      setErrorMessage(
        '入力したパスワードが一致しません。再度入力してください。'
      );
      return;
    }
    setLoading(true);

    try {
      await confirmForgotPassword(mailAddress, code, password);
    } catch (err) {
      if (err instanceof Error)
        setErrorMessage(
          err instanceof APIError ? err.errorMessage : err.message
        );
      throw err;
    } finally {
      setLoading(false);
    }
    navigate('/');
  };

  return (
    <Form<ForgotPasswordSubmitInput>
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      onFinish={finish}
      validateTrigger="onSubmit"
    >
      <h3>パスワード再設定</h3>
      <div className={classes.text}>
        <Text>
          {`指定のメールアドレスに対して検証コードを送信しました。
          メールを確認し、検証コード・新しいパスワードを入力してください。
          ※メールが届かない場合は、担当者にご連絡お願いします。`}
        </Text>
      </div>
      <Form.Item
        label="検証用コード"
        name="code"
        rules={[
          {
            required: true,
            message: '検証用コードを入力してください',
          },
        ]}
      >
        <Input className={classes.verificationCode} />
      </Form.Item>
      <Form.Item
        label="パスワード"
        name="password"
        rules={[{ required: true, message: 'パスワードを入力してください' }]}
      >
        <Input.Password className={classes.password} />
      </Form.Item>
      <Form.Item
        label="パスワード（確認）"
        name="confirmPassword"
        rules={[
          { required: true, message: 'パスワード(確認)を入力してください' },
        ]}
      >
        <Input.Password className={classes.password} />
      </Form.Item>
      {errorMessage && (
        <div className={classes.message}>
          <Text type="danger">{errorMessage}</Text>
        </div>
      )}
      <Form.Item style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          パスワード再設定
        </Button>
      </Form.Item>
      <div className={classes.message}>
        <Button type="link" onClick={() => navigate('/sign-in')}>
          ログイン画面に戻る
        </Button>
      </div>
    </Form>
  );
}
