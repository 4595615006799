import { Auth } from 'aws-amplify';
import axios, { AxiosRequestHeaders } from 'axios';

const { REACT_APP_BACKEND_BASE_URL, REACT_APP_HEADERS_ROLE } = process.env;
const headers = REACT_APP_HEADERS_ROLE
  ? { 'x-bop-user-groups': REACT_APP_HEADERS_ROLE }
  : undefined;

const getInstance = () => {
  const request = axios.create({
    baseURL: REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
    headers,
  });
  request.interceptors.request.use(async (config) => {
    const authorization = `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`;
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: authorization,
      } as AxiosRequestHeaders,
    };
  });
  return request;
};

export default getInstance();
