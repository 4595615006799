import { ErrorMessages } from '../domain/auth';

export const signInErrorMessageList: ErrorMessages = {
  NotAuthorizedException: 'メールアドレスまたはパスワードが間違っています。',
  InvalidPasswordException:
    'パスワードポリシーに違反しています。パスワードを入力し直してください。',
} as const;

export const newPasswordErrorMessageList: ErrorMessages = {
  NotAuthorizedException:
    'セッションの有効期限を超えました。再度ログインしてください。',
  InvalidPasswordException:
    'パスワードポリシーに違反しています。再度入力してください。',
  InvalidParameterException:
    'パスワードポリシーに違反しています。再度入力してください。',
} as const;

export const forgotPasswordErrorMessageList: ErrorMessages = {
  UserNotFoundException: 'メールアドレスが有効ではありません。',
  CodeMismatchException: '検証用コードが正しくありません',
  InvalidPasswordException:
    'パスワードポリシーに違反しています。再度入力してください。',
  InvalidParameterException:
    'パスワードポリシーに違反しています。再度入力してください。',
} as const;
