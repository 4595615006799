import { Auth } from 'aws-amplify';

import {
  forgotPasswordErrorMessageList,
  newPasswordErrorMessageList,
  signInErrorMessageList,
} from '../constants/auth';
import { CognitoUserInfo } from '../domain/auth';
import { APIError } from '../lib';

export const signIn = async (
  username: string,
  password: string
): Promise<CognitoUserInfo> => {
  const user: CognitoUserInfo = await Auth.signIn(username, password).catch(
    (err) => {
      throw new APIError(err.code, err.message, signInErrorMessageList);
    }
  );
  return user;
};

export const completeNewPassword = async (
  user: CognitoUserInfo,
  password: string
): Promise<void> => {
  await Auth.completeNewPassword(
    user,
    password,
    user.challengeParam?.requiredAttributes
  ).catch((err) => {
    throw new APIError(err.code, err.message, newPasswordErrorMessageList);
  });
};

export const forgotPassword = async (username: string): Promise<void> => {
  await Auth.forgotPassword(username).catch((err) => {
    throw new APIError(err.code, err.message, forgotPasswordErrorMessageList);
  });
};

export const confirmForgotPassword = async (
  username: string,
  verifyCode: number,
  password: string
): Promise<void> => {
  await Auth.forgotPasswordSubmit(
    username,
    verifyCode.toString(),
    password
  ).catch((err) => {
    throw new APIError(err.code, err.message, forgotPasswordErrorMessageList);
  });
};
