import { Form, DatePicker, Row, Col, Select } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  manHoursListMonthState,
  manHoursListYearState,
  manHoursYearState,
  manHoursMonthState,
  manHoursDeptCodeState,
} from '../../../recoil/atoms/man-hours';
import classes from '../../../styles/search.module.scss';
import Search from '../../common/Search';

interface Props {
  dept: { label: string | undefined; value: string | undefined }[];
  onResize: () => void;
}

export default function ManHoursTotalListSearch({ dept, onResize }: Props) {
  const [form] = useForm();
  const [year, setListYear] = useRecoilState<Dayjs>(manHoursListYearState);
  const [month, setListMonth] = useRecoilState<Dayjs>(manHoursListMonthState);
  const setYear = useSetRecoilState<Dayjs>(manHoursYearState);
  const setMonth = useSetRecoilState<Dayjs | null>(manHoursMonthState);
  const [deptCode, setDeptCode] = useRecoilState<string>(manHoursDeptCodeState);

  const handleYearChange = (value: dayjs.Dayjs | null) => {
    if (value !== null) {
      setListYear(value);
      setYear(value);
    }
  };

  const handleMonthChange = (value: dayjs.Dayjs | null) => {
    if (value !== null) {
      setListMonth(value);
      setMonth(value);
    }
  };

  const handleDeptNameChange = (value: string | null) => {
    if (value !== null) {
      setDeptCode(value);
    }
  };

  const disableOutOfRangeMonths = (current: dayjs.Dayjs) => {
    return (
      current &&
      (current > dayjs().endOf('year') || current < dayjs(year).startOf('year'))
    );
  };

  return (
    <Search form={form} onResize={onResize}>
      <Row>
        <Col xs={{ span: 19 }} md={{ span: 20 }} lg={{ span: 22 }}>
          <Form.Item
            label="年月"
            name="yearMonth"
            rules={[{ required: true }]}
            className={classes.searchFormItem}
          >
            <>
              <DatePicker
                value={year}
                picker="year"
                onChange={handleYearChange}
                style={{ marginRight: 12 }}
              />
              <DatePicker
                value={month}
                picker="month"
                format="MM"
                disabledDate={disableOutOfRangeMonths}
                onChange={handleMonthChange}
                style={{ maxWidth: 330, marginRight: 12 }}
              />
            </>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 19 }} md={{ span: 20 }} lg={{ span: 22 }}>
          <Form.Item
            name="deptName"
            label="部門名"
            className={classes.searchFormItem}
            initialValue={deptCode}
          >
            <Select
              showSearch
              placeholder="部門名"
              options={dept}
              style={{ maxWidth: 330, marginRight: 12 }}
              onChange={handleDeptNameChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Search>
  );
}
