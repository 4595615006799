import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export const fromYearMonthState = atom<Dayjs>({
  key: 'uploadFilesSearchFromYear',
  default: dayjs(),
});

export const toYearMonthState = atom<Dayjs>({
  key: 'uploadFilesSearchToYear',
  default: dayjs(),
});

export const refreshKeyState = atom({
  key: 'uploadFilesRefreshKey',
  default: true,
});
