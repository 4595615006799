import { App, Layout } from 'antd';
import { Routes } from 'react-router-dom';
import { Route } from 'use-react-router-breadcrumbs';

import classes from '../../styles/common.module.scss';
import { NotFound } from '../pages/NotFound';
import CostAccounting from '../pages/cost-accounting/List';
import CostExpenseDetail from '../pages/cost-expense/Detail';
import CostExpenseList from '../pages/cost-expense/List';
import CostExpenseDetailByMonth from '../pages/cost-expense/MonthDetail';
import ManHoursDetails from '../pages/man-hours/ManHoursDetail';
import ManHoursProjectsDetails from '../pages/man-hours/ManHoursProjectsDetail';
import ManHoursTotalList from '../pages/man-hours/ManHoursTotalList';
import ManHoursTotal from '../pages/man-hours/ManHoursTotalPersonal';
import BpMangeCostEdit from '../pages/manage-cost/BPEdit';
import EmployeeEdit from '../pages/manage-cost/EmployeeEdit';
import UploadAccount from '../pages/upload/Account';
import UploadBpWorkResults from '../pages/upload/BPWorks';
import { UserEdit } from '../pages/users/UserEdit';

import { Breadcrumbs } from './Breadcrumbs';

import { Drawer } from '.';

const { Content } = Layout;

export const generateAppRoute = () => (
  <>
    <Route path="/" element={<CostExpenseList />} breadcrumb="案件収支一覧" />
    <Route
      path="/cost-expense"
      element={<CostExpenseList />}
      breadcrumb="案件収支一覧"
    />
    <Route
      path="/cost-expense/:jobNo"
      element={<CostExpenseDetail />}
      breadcrumb="案件収支詳細"
    />
    <Route
      path="/cost-expense/:jobNo/:yearMonth"
      element={<CostExpenseDetailByMonth />}
      breadcrumb="月別案件収支詳細"
    />
    <Route
      path="/cost-accounting"
      element={<CostAccounting />}
      breadcrumb="経理原価一覧"
    />
    <Route
      path="/man-hours-total"
      element={<ManHoursTotalList />}
      breadcrumb="個人工数集計一覧"
    />
    <Route
      path="/man-hours-total/:empCode"
      element={<ManHoursTotal />}
      breadcrumb="個人工数集計"
    />
    <Route
      path="/man-hours-total/:empCode/projectsDetail"
      element={<ManHoursProjectsDetails />}
      breadcrumb="案件詳細"
    />
    <Route
      path="/man-hours-total/:empCode/manHoursDetail"
      element={<ManHoursDetails />}
      breadcrumb="工数一覧"
    />
    <Route path="/manage-cost/employee" element={<EmployeeEdit />} />
    <Route path="/manage-cost/bp" element={<BpMangeCostEdit />} />
    <Route path="/users" element={<UserEdit />} breadcrumb="ユーザー管理" />
    <Route
      path="/upload/bp-work"
      element={<UploadBpWorkResults />}
      breadcrumb="BP工数実績"
    />
    <Route
      path="/upload/account"
      element={<UploadAccount />}
      breadcrumb="経理原価"
    />
    <Route path="*" element={<NotFound />} />
  </>
);

export function Main() {
  const routers = generateAppRoute();
  return (
    <Layout className={classes.containerLayout}>
      <Drawer />
      <Layout>
        <Content className={classes.contentLayout}>
          <Breadcrumbs />
          <App>
            <Routes>{routers}</Routes>
          </App>
        </Content>
      </Layout>
    </Layout>
  );
}
