import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export const fromYearMonthState = atom<Dayjs>({
  key: 'costExpenseSearchFromYear',
  default: dayjs(),
});

export const toYearMonthState = atom<Dayjs>({
  key: 'costExpenseSearchToYear',
  default: dayjs(),
});
