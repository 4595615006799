import { Col, Row } from 'antd';
import { ReactNode, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { sessionAtom, userPermissionAtom } from '../../../recoil/atoms/auth';
import classes from '../../../styles/auth.module.scss';

import { ForgotPassword } from './ForgotPassword';
import { SignIn } from './SignIn';

interface Props {
  children: ReactNode;
}

export function Auth({ children }: Props) {
  const session = useRecoilValue(sessionAtom);
  const setPermission = useSetRecoilState(userPermissionAtom);

  useEffect(() => {
    (() => {
      if (session != null) {
        setPermission(session.payload['cognito:groups']);
      }
    })();
  }, [session]);

  return session != null ? (
    <div>{children}</div>
  ) : (
    <Row align="middle" justify="center" className={classes.authBackLayout}>
      <Col span={4} />
      <Col span={16} className={classes.authLayout}>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<SignIn />} />
        </Routes>
      </Col>
      <Col span={4} />
    </Row>
  );
}
