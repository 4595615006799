import { CloudSyncOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Popconfirm } from 'antd';

interface Props extends ButtonProps {
  onConfirm: () => Promise<void>;
}
export function DeleteButton({ onConfirm, ...props }: Props) {
  return (
    <Popconfirm title="本当に削除して宜しいですか？" onConfirm={onConfirm}>
      <Button
        name="delete"
        type="primary"
        danger
        icon={<DeleteOutlined />}
        {...props}
      >
        削除
      </Button>
    </Popconfirm>
  );
}

export function ReflectButton({ onConfirm, ...props }: Props) {
  return (
    <Popconfirm
      title="データを反映しますが、宜しいですか？"
      onConfirm={onConfirm}
      disabled={props.disabled}
    >
      <Button icon={<CloudSyncOutlined />} danger {...props} />
    </Popconfirm>
  );
}
