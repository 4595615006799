import { ErrorMessages } from '../domain/auth';

export class APIError extends Error {
  code: string;

  errorMessage: string;

  constructor(code: string, message: string, mappings?: ErrorMessages) {
    super(message);
    this.code = code;

    this.name = this.constructor.name;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.errorMessage =
      mappings !== undefined ? mappings[this.code] ?? message : message;
  }
}
