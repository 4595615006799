import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { PersonalSummaries } from '../../../domain/man-hours';
import { getPersonalSummaries } from '../../../helpers/man-hours';
import {
  manHoursListYearState,
  manHoursListMonthState,
  manHoursDeptCodeState,
} from '../../../recoil/atoms/man-hours';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import ManHoursTotalListSearch from './ManHoursTotalListSearch';
import ManHoursTotalListTable from './ManHoursTotalListTable';

type Dept = { label: string | undefined; value: string | undefined }[];

function ManHoursTotalList() {
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);
  const [showErrorNotification] = useErrorNotification();
  const [personalMh, setPersonalMh] = useState<PersonalSummaries[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const searchHeight = showSearch ? 106 : 56;
  const yearState = useRecoilValue(manHoursListYearState);
  const monthState = useRecoilValue(manHoursListMonthState);
  const deptCodeState = useRecoilValue(manHoursDeptCodeState);
  const [dept, setDept] = useState<Dept>([]);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  useEffect(() => {
    (async () => {
      if (yearState == null || monthState == null) return;

      const year = Number(yearState.format('YYYY'));
      const month = Number(monthState.format('MM'));

      setLoading(true);
      try {
        const personalMhValue = await getPersonalSummaries(year, month);
        const d = Array.from(
          new Map(
            personalMhValue.map((p) => [p.deptCode ?? '', p.deptName ?? ''])
          ).set('', '')
        )
          .sort()
          .map((p) => {
            return { label: p[1], value: p[0] };
          });

        setDept(d);
        setPersonalMh(personalMhValue);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [yearState, monthState]);

  return (
    <>
      <ManHoursTotalListSearch dept={dept} onResize={handleSearchResize} />
      <ManHoursTotalListTable
        personalMh={personalMh.filter(
          (p) => p.deptCode === deptCodeState || deptCodeState === ''
        )}
        searchHeight={searchHeight}
        loading={loading}
      />
    </>
  );
}

export default ManHoursTotalList;
