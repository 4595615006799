const descendingComparator = <TRow>(
  a: TRow,
  b: TRow,
  orderBy: keyof TRow
): number => {
  switch (true) {
    case a[orderBy] === undefined && b[orderBy] === undefined:
      return 0;
    case a[orderBy] === undefined:
      return -1;
    case b[orderBy] === undefined:
      return 1;
    case b[orderBy] < a[orderBy]:
      return -1;
    case b[orderBy] > a[orderBy]:
      return 1;
    default:
      return 0;
  }
};

export { descendingComparator };
