import { Link } from 'react-router-dom';

export const detailLink = (year: number, month: number, empCode: string) => {
  return (
    <div>
      <Link
        style={{ margin: '10px' }}
        to={`/man-hours-total/${empCode}/projectsDetail?month=${month}&year=${year}`}
      >
        案件詳細
      </Link>
      <Link
        style={{ margin: '10px' }}
        to={`/man-hours-total/${empCode}/manHoursDetail?month=${month}&year=${year}`}
      >
        工数一覧
      </Link>
    </div>
  );
};
