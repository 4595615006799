import { Alert, ConfigProvider, Spin } from 'antd';
import 'dayjs/locale/ja';
import locale from 'antd/locale/ja_JP';
import { Amplify } from 'aws-amplify';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import './App.css';
import { Main } from './components/common';
import { Auth } from './components/pages/auth/Auth';
import classes from './styles/common.module.scss';

const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT,
} = process.env;

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: REACT_APP_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: REACT_APP_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT,
  },
});

function App() {
  const { ErrorBoundary } = Alert;
  return (
    <ConfigProvider locale={locale}>
      <RecoilRoot>
        <ErrorBoundary>
          <Suspense
            fallback={
              <Spin
                tip="loading..."
                size="large"
                className={classes.loadingSpin}
              />
            }
          >
            <BrowserRouter>
              <Auth>
                <Main />
              </Auth>
            </BrowserRouter>
          </Suspense>
        </ErrorBoundary>
      </RecoilRoot>
    </ConfigProvider>
  );
}

export default App;
