import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Layout, Tooltip } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { sessionAtom } from '../../recoil/atoms/auth';
import { openDrawerState } from '../../recoil/atoms/drawer';
import classes from '../../styles/common.module.scss';

import { Menu } from '.';

const { Sider } = Layout;

export function Drawer() {
  const [open, setOpen] = useRecoilState(openDrawerState);
  const setSession = useSetRecoilState(sessionAtom);
  const [openLogoutToolTip, setOpenLogoutToolTip] = useState(false);

  const signOut = async () => {
    await Auth.signOut();
    setSession(null);
  };

  return (
    <Sider
      trigger={null}
      collapsible
      theme="light"
      collapsed={!open}
      width={230}
    >
      <div className={classes.sliderChildren}>
        <Tooltip
          placement="right"
          title={open ? 'メニューを閉じる' : 'メニューを開く'}
        >
          <Button
            type="text"
            className={classes.drawerButton}
            icon={open ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
            onClick={() => setOpen(!open)}
            style={
              open === false ? { marginLeft: 'auto', marginRight: 'auto' } : {}
            }
          />
        </Tooltip>
        <Menu />
        <Tooltip
          placement="right"
          title="ログアウト"
          onOpenChange={(toolOpen) =>
            setOpenLogoutToolTip(toolOpen && open === false)
          }
          open={openLogoutToolTip}
        >
          <Button
            className={classes.logoutButton}
            type="link"
            icon={<LogoutOutlined />}
            onClick={signOut}
            style={
              open === false ? { marginLeft: 'auto', marginRight: 'auto' } : {}
            }
          >
            {open ? 'ログアウト' : ''}
          </Button>
        </Tooltip>
      </div>
    </Sider>
  );
}
