import dayjs, { Dayjs } from 'dayjs';
import { atom } from 'recoil';

export const manHoursYearState = atom<Dayjs>({
  key: 'manHoursYear',
  default: dayjs().startOf('year'),
});

export const manHoursMonthState = atom<Dayjs | null>({
  key: 'manHoursMonth',
  default: dayjs().startOf('month'),
});

export const manHoursListYearState = atom<Dayjs>({
  key: 'manHoursListYear',
  default: dayjs().startOf('year'),
});

export const manHoursListMonthState = atom<Dayjs>({
  key: 'manHoursListMonth',
  default: dayjs().startOf('month'),
});

export const manHoursDeptCodeState = atom<string>({
  key: 'manHoursListDeptCode',
  default: '',
});
