import { ColumnsType } from 'antd/es/table';

import { PersonalDetail } from '../../../domain/man-hours';
import { table, uniqueByColumn } from '../../../lib';
import { getColumnSearchProps } from '../../common';
import Table from '../../common/Table';

const TABLE_HEADER_HEIGHT = 62;

interface Props {
  personalMhList: PersonalDetail[];
  searchHeight: number;
  loading: boolean;
}

export default function ManHoursDetailTable({
  personalMhList,
  searchHeight,
  loading = false,
}: Props) {
  const columns: ColumnsType<PersonalDetail> = [
    {
      title: '作業日',
      dataIndex: 'workDate',
      key: 'workDate',
      width: 80,
      align: 'center',
      sorter: (a, b) => table.descendingComparator(a, b, 'workDate'),
      ...getColumnSearchProps(
        'workDate',
        '作業日',
        uniqueByColumn(personalMhList, 'workDate').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      defaultSortOrder: 'descend',
    },
    {
      title: 'PJ番号',
      dataIndex: 'projectCode',
      width: 70,
      align: 'center',
      sorter: (a, b) => table.descendingComparator(a, b, 'projectCode'),
      ...getColumnSearchProps(
        'projectCode',
        'PJ番号',
        uniqueByColumn(personalMhList, 'projectCode').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: 'プロジェクト名',
      dataIndex: 'projectName',
      width: 200,
      sorter: (a, b) => table.descendingComparator(a, b, 'projectName'),
      ...getColumnSearchProps(
        'projectName',
        'プロジェクト名',
        uniqueByColumn(personalMhList, 'projectName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: 'JOBNo',
      dataIndex: 'jobNo',
      width: 80,
      align: 'center',
      sorter: {
        compare: (a, b) => table.descendingComparator(a, b, 'jobNo'),
        multiple: 1,
      },
      ...getColumnSearchProps(
        'jobNo',
        'JOBNo',
        uniqueByColumn(personalMhList, 'jobNo').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: '案件名',
      dataIndex: 'projectTitle',
      width: 250,
      sorter: (a, b) => table.descendingComparator(a, b, 'projectTitle'),
      ...getColumnSearchProps(
        'projectTitle',
        '案件名',
        uniqueByColumn(personalMhList, 'projectTitle').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
    },
    {
      title: '実績工数\n(時間)',
      dataIndex: 'manHour',
      width: 70,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
    },
    {
      title: '備考',
      dataIndex: 'note',
      width: 200,
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      width: 100,
      align: 'center',
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  return (
    <Table<PersonalDetail>
      rowKey="id"
      dataSource={personalMhList}
      columns={columns}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
