import { RcFile } from 'antd/es/upload';

import { uploadBpWorksFile } from '../../../helpers/upload';
import FileUpload from '../../common/FIleUpload';
import { useErrorNotification } from '../../hooks/error-notification';

import UploadedFiles from './List';

export default function UploadBpWork() {
  const [showErrorNotification] = useErrorNotification();

  const handleUpload = async (file: RcFile) => {
    try {
      await uploadBpWorksFile(file);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
    }
  };

  return (
    <>
      <h2>BP工数アップロード</h2>
      <FileUpload onUpload={handleUpload} />
      <UploadedFiles fileType="bp_work_results" />
    </>
  );
}
