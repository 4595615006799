import {
  PersonalApi,
  ProjectApi,
  MdmApi,
  MembersApi,
  AccountApi,
  UploadApi,
} from '../server';

import axios from './axios';

const { REACT_APP_BACKEND_BASE_URL } = process.env;

const projectApi = new ProjectApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

const mdmApi = new MdmApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

const personalApi = new PersonalApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

const accountApi = new AccountApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

const membersApi = new MembersApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

const uploadApi = new UploadApi(
  undefined,
  REACT_APP_BACKEND_BASE_URL ?? 'http://localhost:8000',
  axios
);

export { personalApi, projectApi, mdmApi, accountApi, membersApi, uploadApi };
