import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import { SubmitAddMemberInput, SubmitEditMemberInput } from '../domain/users';
import { api } from '../lib';
import { MemberAddRequest, MemberUpdateRequest } from '../server';

export const getMemberList = (showAdditionalLoginInfo: boolean) =>
  api.membersApi.getMembersList(showAdditionalLoginInfo).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true });
    return results.map((user) => ({
      ...user,
      createDate: new Date(user.createDate).toLocaleString(),
      updateDate: new Date(user.updateDate).toLocaleString(),
    }));
  });

export const addMember = (addLoginUserInput: SubmitAddMemberInput) =>
  api.membersApi
    .addMember(snakecaseKeys(addLoginUserInput) as MemberAddRequest)
    .then((res) => {
      const results = camelcaseKeys(res.data, { deep: true });
      return results.map((user) => ({
        ...user,
        createDate: new Date(user.createDate).toLocaleString(),
        updateDate: new Date(user.updateDate).toLocaleString(),
      }));
    });

export const editMember = (editLoginUserInput: SubmitEditMemberInput) =>
  api.membersApi
    .updateMember(snakecaseKeys(editLoginUserInput) as MemberUpdateRequest)
    .then((res) => {
      const results = camelcaseKeys(res.data, { deep: true });
      return results.map((user) => ({
        ...user,
        createDate: new Date(user.createDate).toLocaleString(),
        updateDate: new Date(user.updateDate).toLocaleString(),
      }));
    });

export const deleteMember = (userId: string) =>
  api.membersApi.deleteMember(userId).then((res) => {
    const results = camelcaseKeys(res.data, { deep: true });
    return results.map((user) => ({
      ...user,
      createDate: new Date(user.createDate).toLocaleString(),
      updateDate: new Date(user.updateDate).toLocaleString(),
    }));
  });
