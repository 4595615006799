import { Button, Form, Input, Typography } from 'antd';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { CognitoUserInfo, PasswordResetInput } from '../../../domain/auth';
import { completeNewPassword } from '../../../helpers/auth';
import { APIError } from '../../../lib';
import { sessionAtom } from '../../../recoil/atoms/auth';
import classes from '../../../styles/auth.module.scss';

const { Text } = Typography;

interface Props {
  user: CognitoUserInfo | undefined;
  clear: () => void;
}

export function NewPassword({ user, clear }: Props) {
  const setSession = useSetRecoilState(sessionAtom);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const finish = async (value: PasswordResetInput) => {
    if (value.password !== value.confirmPassword) {
      setErrorMessage(
        '入力したパスワードが一致しません。再度入力してください。'
      );
      return;
    }

    setLoading(true);
    if (user === undefined) {
      throw new Error('PasswordReset function is user parameter required');
    }
    try {
      await completeNewPassword(user, value.password);
      const session = (await Auth.currentSession()).getIdToken();
      setSession(session);
    } catch (err) {
      if (err instanceof Error)
        setErrorMessage(
          err instanceof APIError ? err.errorMessage : err.message
        );
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<PasswordResetInput>
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      onFinish={finish}
      validateTrigger="onSubmit"
    >
      <h3>初回パスワード変更</h3>
      <div className={classes.text}>
        <Text>{`初回ログイン時はパスワードの変更が必要です。
        ※以下のパスワードポリシーを満たすパスワードを設定する必要があります。
          ・最小文字数: 8文字以上
          ・少なくとも一つ以上の数字
          ・少なくとも一つ以上の大文字
          ・少なくとも一つ以上の小文字
          ・少なくとも一つ以上の記号`}</Text>
      </div>
      <Form.Item
        label="パスワード"
        name="password"
        rules={[{ required: true, message: 'パスワードを入力してください' }]}
      >
        <Input.Password className={classes.password} />
      </Form.Item>
      <Form.Item
        label="パスワード（確認）"
        name="confirmPassword"
        rules={[
          { required: true, message: 'パスワード（確認）を入力してください' },
        ]}
      >
        <Input.Password className={classes.password} />
      </Form.Item>
      {errorMessage && (
        <div className={classes.message}>
          <Text type="danger">{errorMessage}</Text>
        </div>
      )}
      <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          パスワード設定
        </Button>
      </Form.Item>
      <div className={classes.message}>
        <Button type="link" onClick={() => clear()}>
          ログイン画面に戻る
        </Button>
      </div>
    </Form>
  );
}
