import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { useState } from 'react';

import classes from '../../styles/search.module.scss';

interface Props<T> {
  form?: FormInstance<T>;
  onSearch?: () => void;
  onResize?: () => void;
  onFinish?: () => void;
  children: React.ReactNode;
}

export default function Search<T>({
  form,
  children,
  onSearch,
  onResize,
  onFinish,
}: Props<T>) {
  const [showSearch, setShowSearch] = useState(true);
  return (
    <Form<T>
      form={form}
      className={classes.searchLayout}
      style={showSearch === false ? { paddingBottom: 12 } : {}}
      onBlur={() => onSearch && onSearch()}
      onFinish={() => onFinish && onFinish()}
      validateTrigger="onSubmit"
    >
      <Button
        type="link"
        icon={showSearch ? <DownOutlined /> : <UpOutlined />}
        onClick={() => {
          setShowSearch(showSearch === false);
          if (onResize !== undefined) onResize();
        }}
        className={classes.hiddenSearchButton}
      >
        {showSearch ? '検索条件を隠す' : '検索条件を表示'}
      </Button>
      {showSearch && children}
    </Form>
  );
}
