import { Button, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordInput } from '../../../domain/auth';
import { forgotPassword } from '../../../helpers/auth';
import { APIError } from '../../../lib';
import classes from '../../../styles/auth.module.scss';

import { ForgotPasswordSubmit } from './ForgotPasswordSubmit';

const { Text } = Typography;

export function ForgotPassword() {
  const navigate = useNavigate();
  const [mailAddress, setMailAddress] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const finish = async (value: ForgotPasswordInput) => {
    setErrorMessage('');
    setLoading(true);
    try {
      await forgotPassword(value.username);
    } catch (err) {
      if (err instanceof Error)
        setErrorMessage(
          err instanceof APIError ? err.errorMessage : err.message
        );
      throw err;
    } finally {
      setLoading(false);
    }
    setMailAddress(value.username);
  };

  return mailAddress !== undefined ? (
    <ForgotPasswordSubmit mailAddress={mailAddress} />
  ) : (
    <Form<ForgotPasswordInput>
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      autoComplete="off"
      onFinish={finish}
      validateTrigger="onSubmit"
    >
      <h3>パスワード再設定</h3>
      <div className={classes.text}>
        <Text>
          {`パスワードを忘れた場合、指定のメールアドレスに対して検証コードを送信します。
          検証コードを入力することで、パスワードをリセットすることができます。`}
        </Text>
      </div>
      <Form.Item
        label="メールアドレス"
        name="username"
        rules={[
          {
            type: 'email',
            message: '入力されたメールアドレスは有効ではありません',
          },
          { required: true, message: 'メールアドレスを入力してください' },
        ]}
      >
        <Input />
      </Form.Item>
      {errorMessage && (
        <div className={classes.message}>
          <Text type="danger">{errorMessage}</Text>
        </div>
      )}
      <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          検証用コードを送信
        </Button>
      </Form.Item>
      <div className={classes.message}>
        <Button type="link" onClick={() => navigate('/sign-in')}>
          ログイン画面に戻る
        </Button>
      </div>
    </Form>
  );
}
