import { ColumnsType } from 'antd/lib/table';

import { UploadFiles } from '../../../domain/upload-files';
import { table, uniqueByColumn } from '../../../lib';
import { getColumnSearchProps } from '../../common';
import Table from '../../common/Table';

interface Props {
  uploadFilesList: UploadFiles[];
  searchHeight: number;
  loading: boolean;
}

const TABLE_HEADER_HEIGHT = 62;

export default function UploadFilesTable({
  uploadFilesList,
  searchHeight,
  loading,
}: Props) {
  const columns: ColumnsType<UploadFiles> = [
    {
      title: 'ファイル名',
      dataIndex: 'fileName',
      key: 'fileName',
      width: 80,
      fixed: 'left',
      ...getColumnSearchProps(
        'fileName',
        'ファイル名',
        uniqueByColumn(uploadFilesList, 'fileName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'fileName'),
    },
    {
      title: 'ファイル格納場所',
      dataIndex: 'filePath',
      key: 'filePath',
      width: 120,
      fixed: 'left',
      ...getColumnSearchProps(
        'filePath',
        'ファイル格納場所',
        uniqueByColumn(uploadFilesList, 'filePath').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'filePath'),
    },
    {
      title: 'ステータス',
      dataIndex: 'fileStatus',
      key: 'fileStatus',
      width: 50,
      align: 'center',
      ...getColumnSearchProps(
        'fileStatus',
        'ステータス',
        uniqueByColumn(uploadFilesList, 'fileStatus').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'fileStatus'),
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      key: 'updateDate',
      width: 50,
      align: 'center',
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  return (
    <Table<UploadFiles>
      rowKey="id"
      dataSource={uploadFilesList}
      columns={columns}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
