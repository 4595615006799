import {
  TransactionOutlined,
  PieChartOutlined,
  PoundCircleOutlined,
  ToolOutlined,
  UserOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import { ItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userPermissionAtom } from '../../recoil/atoms/auth';
import { RoleEnum } from '../../server';

type MenuItemType = ItemType & {
  permissions?: RoleEnum[];
  children?: MenuItemType[];
};

const menuItems: MenuItemType[] = [
  {
    label: '案件収支一覧',
    icon: <TransactionOutlined />,
    key: '/cost-expense',
    permissions: [RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal],
  },
  {
    label: '個人工数集計一覧',
    icon: <PieChartOutlined />,
    key: '/man-hours-total',
    permissions: [RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal],
  },
  {
    label: '経理原価一覧',
    icon: <PoundCircleOutlined />,
    key: '/cost-accounting',
    permissions: [RoleEnum.Admin, RoleEnum.Head],
  },
  {
    label: 'アップロード',
    key: 'upload',
    icon: <UploadOutlined />,
    children: [
      {
        label: 'BP工数実績',
        key: '/upload/bp-work',
        permissions: [RoleEnum.Admin],
      },
      {
        label: '経理原価',
        key: '/upload/account',
        permissions: [RoleEnum.Admin],
      },
    ],
  },
  {
    label: 'メンテナンス',
    key: 'maintenance',
    icon: <ToolOutlined />,
    children: [
      {
        label: '社員原価マスタ管理',
        key: '/manage-cost/employee',
        permissions: [RoleEnum.Admin],
      },
      {
        label: 'BP原価マスタ修正',
        key: '/manage-cost/bp',
        permissions: [RoleEnum.Admin],
      },
    ],
  },
  {
    label: 'ユーザ―管理',
    icon: <UserOutlined />,
    key: '/users',
    permissions: [RoleEnum.Admin],
  },
];

// eslint-disable-next-line consistent-return
const searchMenuItem = (path: string): ItemType | undefined => {
  if (path === '/') return menuItems[0];

  // eslint-disable-next-line no-restricted-syntax
  for (const item of menuItems) {
    if (item?.key === path) return item;
    const children = (item as SubMenuType)?.children?.find(
      (menu) => menu?.key === path
    );
    if (children !== undefined) return item;
  }
};

function hasPermissionForItem(
  item: MenuItemType,
  permissions: RoleEnum[] | null | undefined = [RoleEnum.Normal]
): boolean {
  return (
    item.permissions !== undefined &&
    permissions !== null &&
    permissions.some((permission) => item.permissions?.includes(permission))
  );
}

function filterMenuItemsByPermission(
  items: MenuItemType[],
  permissions: RoleEnum[] | null
): MenuItemType[] {
  return items.flatMap((item) => {
    const hasPermission = hasPermissionForItem(item, permissions);

    if (item.children) {
      const filteredChildren = filterMenuItemsByPermission(
        item.children,
        permissions
      );
      if (filteredChildren.length > 0) {
        return [{ ...item, children: filteredChildren }];
      }
    }

    return hasPermission ? item : [];
  });
}

export default function Menu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const permissions = useRecoilValue<RoleEnum[] | null>(userPermissionAtom);

  const onSelectMenu: SelectEventHandler = (e) => {
    navigate(e.key);
  };

  return (
    <AntMenu
      theme="light"
      mode="inline"
      style={{ borderInlineEnd: 'none' }}
      defaultSelectedKeys={[searchMenuItem(pathname)?.key?.toString() ?? '/']}
      defaultOpenKeys={['maintenance']}
      items={filterMenuItemsByPermission(menuItems, permissions)}
      onSelect={onSelectMenu}
    />
  );
}
