import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, DatePicker, Row, Col } from 'antd';
import useForm from 'antd/es/form/hooks/useForm';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  manHoursMonthState,
  manHoursYearState,
} from '../../../recoil/atoms/man-hours';
import { memberAtom } from '../../../recoil/atoms/member';
import classes from '../../../styles/search.module.scss';
import Search from '../../common/Search';

interface Props {
  empCode: string;
  onResize: () => void;
}

export default function ManHoursTotalPersonalSearch({
  empCode,
  onResize,
}: Props) {
  const [form] = useForm();
  const [year, setYear] = useRecoilState<Dayjs>(manHoursYearState);
  const [month, setMonth] = useRecoilState<Dayjs | null>(manHoursMonthState);
  const members = useRecoilValue(memberAtom);
  const member = members.find((m) => m.empCode === empCode);

  const handleYearChange = (value: dayjs.Dayjs | null) => {
    if (value !== null) setYear(value);
  };

  const handleMonthChange = (value: dayjs.Dayjs | null) => {
    setMonth(value);
  };

  const disableOutOfRangeMonths = (current: dayjs.Dayjs) => {
    return (
      current &&
      (current > dayjs().endOf('year') || current < dayjs(year).startOf('year'))
    );
  };

  return (
    <Search form={form} onResize={onResize}>
      <Row>
        <Col xs={{ span: 19 }} md={{ span: 20 }} lg={{ span: 22 }}>
          <Form.Item
            label="年月"
            name="yearMonth"
            rules={[{ required: true }]}
            tooltip={{
              title:
                '月項目を選択した場合、指定した月以降が表示されます。※月項目は省略可能です。',
              icon: <InfoCircleOutlined />,
            }}
            className={classes.searchFormItem}
          >
            <>
              <DatePicker
                value={year}
                picker="year"
                onChange={handleYearChange}
                style={{ marginRight: 12 }}
              />
              <DatePicker
                value={month}
                picker="month"
                format="MM"
                disabledDate={disableOutOfRangeMonths}
                onChange={handleMonthChange}
              />
            </>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <Form.Item
            label="作業者コード"
            className={classes.searchFormItemLabel}
          >
            <p>{member?.empCode}</p>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 4 }}
          xxl={{ span: 3 }}
        >
          <Form.Item label="氏名" className={classes.searchFormItemLabel}>
            <p>{member?.empName}</p>
          </Form.Item>
        </Col>
      </Row>
    </Search>
  );
}
