import { atom } from 'recoil';

import { RoleEnum } from '../../server';
import { sessionSelector } from '../selector/auth';

export const sessionAtom = atom({
  key: 'sessionAtom',
  default: sessionSelector,
});

export const userPermissionAtom = atom<RoleEnum[] | null>({
  key: 'userPermissionAtom',
  default: null,
});
