import { Typography } from 'antd';

const { Title } = Typography;

export function NotFound() {
  return (
    <>
      <Title level={3}>404 Not Found</Title>
      <Title level={4}>指定されたページは見つかりませんでした</Title>
    </>
  );
}
