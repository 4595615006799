import { App } from 'antd';
import { useEffect, useState } from 'react';

import { EditBpCost, SaveBpCost } from '../../../domain/manage-cost';
import {
  deleteBpList,
  getBpCostList,
  saveBpCostList,
} from '../../../helpers/manage-cost';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import BpEditTable from './BPEditTable';

export default function BpMangeCostEdit() {
  const { message } = App.useApp();
  const [showErrorNotification] = useErrorNotification();
  useCheckUserPermission([RoleEnum.Admin]);
  const [loading, setLoading] = useState(false);
  const [bpCostList, setBpCostList] = useState<EditBpCost[]>([]);

  const loadEmployeeList = async () => {
    setLoading(true);
    try {
      const res = await getBpCostList();
      setBpCostList(res);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => loadEmployeeList())();
  }, []);

  const handleEdit = (row: EditBpCost, columnName: keyof EditBpCost) => {
    const list = bpCostList.map((bp) => {
      return {
        ...bp,
        [columnName]: bp.bpNo === row.bpNo ? row[columnName] : bp[columnName],
        edited: bp.edited === true || bp.bpNo === row.bpNo,
      };
    });
    setBpCostList(list);
  };

  const handleSave = async () => {
    const editList = bpCostList.filter((employee) => employee.edited === true);

    const validateIndex = bpCostList.findIndex(
      (edit) => edit.edited === true && edit.stdCostAmnt == null
    );

    if (validateIndex !== -1) {
      message.error(
        `標準単価は入力する必要があります。行 ${validateIndex + 1}`
      );
      return;
    }

    const list = editList.filter(
      (bp): bp is SaveBpCost => bp.stdCostAmnt !== undefined
    );

    try {
      const res = await saveBpCostList(list);
      setBpCostList(res);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    }
  };

  const handleDelete = async (rows: EditBpCost[]) => {
    try {
      const results = await deleteBpList(rows);
      setBpCostList(results);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    }
  };

  return (
    <BpEditTable
      manageBpCostList={bpCostList}
      loading={loading}
      onEdit={handleEdit}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
}
