import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { userPermissionAtom } from '../../recoil/atoms/auth';
import { RoleEnum } from '../../server';

export const useCheckUserPermission = (
  allowPermissions: RoleEnum[],
  when = true
) => {
  const navigate = useNavigate();
  const userPermission = useRecoilValue(userPermissionAtom);
  const { notification } = App.useApp();
  const [isAccess, setIsAccess] = useState<boolean>();

  useEffect(() => {
    if (
      userPermission != null &&
      userPermission.find((permission) =>
        allowPermissions.includes(permission)
      ) == null &&
      when
    ) {
      notification.error({
        message: '権限がありません',
        placement: 'bottomLeft',
      });
      navigate('/404');
    } else {
      setIsAccess(true);
    }
  }, [userPermission]);

  return isAccess;
};
