import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PersonalSummaryOutput } from '../../../domain/man-hours';
import { getPersonalSummary } from '../../../helpers/man-hours';
import {
  manHoursYearState,
  manHoursMonthState,
} from '../../../recoil/atoms/man-hours';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import ManHoursTotalPersonalSearch from './ManHoursTotalPersonalSearch';
import ManHoursTotalPersonalTable from './ManHoursTotalPersonalTable';

function ManHoursTotalPersonal() {
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);
  const [showErrorNotification] = useErrorNotification();
  const [personalMh, setPersonalMh] = useState<PersonalSummaryOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const searchHeight = showSearch ? 106 : 56;

  const year = useRecoilValue<Dayjs>(manHoursYearState);
  const month = useRecoilValue<Dayjs | null>(manHoursMonthState);
  const location = useLocation();
  const [, , empCode] = location.pathname.split('/');

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  useEffect(() => {
    (async () => {
      if (year == null) return;

      setLoading(true);
      try {
        const personalMhValue = await getPersonalSummary(
          empCode,
          Number(year.format('YYYY')),
          month != null ? Number(month.format('MM')) : undefined
        );
        setPersonalMh(personalMhValue);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [year, month]);

  return (
    <>
      <ManHoursTotalPersonalSearch
        empCode={empCode}
        onResize={handleSearchResize}
      />
      <ManHoursTotalPersonalTable
        personalMh={personalMh}
        searchHeight={searchHeight}
        loading={loading}
      />
    </>
  );
}

export default ManHoursTotalPersonal;
