import { ColumnsType } from 'antd/es/table';

import { ProjectMonthlyDetail } from '../../../domain/cost-expense';
import { table } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { Table } from '../../common';

const TABLE_HEADER_HEIGHT = 79;

const columns: ColumnsType<ProjectMonthlyDetail> = [
  {
    title: '作業者',
    children: [
      {
        title: 'コード',
        dataIndex: 'empCode',
        width: 80,
        fixed: 'left',
        sorter: (a, b) => table.descendingComparator(a, b, 'empCode'),
        defaultSortOrder: 'descend',
      },
      {
        title: '氏名',
        dataIndex: 'empName',
        width: 160,
        fixed: 'left',
        sorter: (a, b) => table.descendingComparator(a, b, 'empName'),
      },
    ],
  },
  {
    title: '予定原価\n（円）',
    dataIndex: 'planCostAmnt',
    width: 100,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'planCostAmnt'),
  },
  {
    title: '実績原価\n（円）',
    dataIndex: 'costAmnt',
    key: 'costAmnt',
    width: 100,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'costAmnt'),
  },
  {
    title: '原価予測\n（円）',
    dataIndex: 'forecastCostAmnt',
    width: 100,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'forecastCostAmnt'),
  },
  {
    title: '予定工数\n（時間）',
    dataIndex: 'planManHour',
    width: 100,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
  },
  {
    title: '実績工数\n（時間）',
    dataIndex: 'manHour',
    width: 100,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
  },
  {
    title: '工数\n進捗率',
    dataIndex: 'manHourProgressRate',
    width: 100,
    align: 'right',
    render: (value: number) => `${value ?? '0.00'} %`,
    onCell: ({ manHourProgressRate }) => ({
      className: (manHourProgressRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
    sorter: (a, b) => table.descendingComparator(a, b, 'manHourProgressRate'),
  },
  {
    title: '工数予測\n（時間）',
    dataIndex: 'forecastManHour',
    key: 'forecastManHour',
    width: 100,
    align: 'right',
    onCell: ({ forecastManHour, planManHour }) => ({
      className:
        (forecastManHour ?? 0) > (planManHour ?? 0) ? classes.dangerColor : '',
    }),
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'forecastManHour'),
  },
  {
    title: '工数消化率\n（予測）',
    dataIndex: 'forecastManHourUseRate',
    width: 100,
    align: 'right',
    onCell: ({ forecastManHourUseRate }) => ({
      className: (forecastManHourUseRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
    render: (value: number) => `${value ?? '0.00'} %`,
    sorter: (a, b) =>
      table.descendingComparator(a, b, 'forecastManHourUseRate'),
  },
  {
    title: '更新日',
    dataIndex: 'updateDate',
    key: 'updateDate',
    width: 100,
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
  },
];

interface Props {
  monthDetail: ProjectMonthlyDetail[];
  loading?: boolean;
  heightSize: number;
}

export default function MonthDetailTable({
  monthDetail,
  loading = false,
  heightSize,
}: Props) {
  return (
    <Table<ProjectMonthlyDetail>
      rowKey="empCode"
      columns={columns}
      dataSource={monthDetail}
      heightSize={heightSize + TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
