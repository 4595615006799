/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountCostResponse
 */
export interface AccountCostResponse {
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_rep_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_manager_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'project_manager_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'project_sales_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'project_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'gross_margin_rate': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'account_sales_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'account_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'account_gross_rate': number;
    /**
     * 
     * @type {number}
     * @memberof AccountCostResponse
     */
    'diff_account_cost': number;
    /**
     * 
     * @type {string}
     * @memberof AccountCostResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface EmployeeCostDeleteRequest
 */
export interface EmployeeCostDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostDeleteRequest
     */
    'hq_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostDeleteRequest
     */
    'dept_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostDeleteRequest
     */
    'group_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostDeleteRequest
     */
    'job_position': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostDeleteRequest
     */
    'zone': string;
}
/**
 * リクエストのスキーマ
 * @export
 * @interface EmployeeCostListPutRequest
 */
export interface EmployeeCostListPutRequest {
    /**
     * 
     * @type {Array<EmployeeCostPutRequest>}
     * @memberof EmployeeCostListPutRequest
     */
    'employee_cost_list': Array<EmployeeCostPutRequest>;
}
/**
 * 
 * @export
 * @interface EmployeeCostPutRequest
 */
export interface EmployeeCostPutRequest {
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostPutRequest
     */
    'hq_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostPutRequest
     */
    'dept_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostPutRequest
     */
    'group_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostPutRequest
     */
    'job_position': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostPutRequest
     */
    'zone': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCostPutRequest
     */
    'std_cost_amnt': number;
}
/**
 * 
 * @export
 * @interface EmployeeCostResponse
 */
export interface EmployeeCostResponse {
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'hq_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'hq_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'dept_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'dept_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'group_code': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'group_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'job_position': string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'zone': string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeCostResponse
     */
    'std_cost_amnt': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeCostResponse
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeCostResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface ManageBpCostPutRequest
 */
export interface ManageBpCostPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'bp_no': string;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'accept_dept': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'beex_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'outsource_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'contract_start_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostPutRequest
     */
    'contract_end_date': string | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostPutRequest
     */
    'std_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostPutRequest
     */
    'contract_reduce_time': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostPutRequest
     */
    'contract_over_time': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostPutRequest
     */
    'contract_reduce_amnt': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostPutRequest
     */
    'contract_over_amnt': number | null;
}
/**
 * 
 * @export
 * @interface ManageBpCostResponse
 */
export interface ManageBpCostResponse {
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'bp_no': string;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'outsource_name': string;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'bp_name': string;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'accept_dept': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'beex_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'outsource_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'contract_start_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'contract_end_date': string | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostResponse
     */
    'std_cost_amnt': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostResponse
     */
    'contract_reduce_time': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostResponse
     */
    'contract_over_time': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostResponse
     */
    'contract_reduce_amnt': number | null;
    /**
     * 
     * @type {number}
     * @memberof ManageBpCostResponse
     */
    'contract_over_amnt': number | null;
    /**
     * 
     * @type {string}
     * @memberof ManageBpCostResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface MemberAddRequest
 */
export interface MemberAddRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberAddRequest
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof MemberAddRequest
     */
    'emp_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof MemberAddRequest
     */
    'email': string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof MemberAddRequest
     */
    'role': RoleEnum;
}


/**
 * 
 * @export
 * @interface MemberUpdateRequest
 */
export interface MemberUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateRequest
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof MemberUpdateRequest
     */
    'role': RoleEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateRequest
     */
    'leave_date'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateRequest
     */
    'is_deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateRequest
     */
    'use_login_only'?: boolean | null;
}


/**
 * 
 * @export
 * @interface MembersResponse
 */
export interface MembersResponse {
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'emp_name': string;
    /**
     * 
     * @type {boolean}
     * @memberof MembersResponse
     */
    'use_login_only': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MembersResponse
     */
    'is_deleted': boolean;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'user_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'user_states'?: string | null;
    /**
     * 
     * @type {RoleEnum}
     * @memberof MembersResponse
     */
    'role'?: RoleEnum | null;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'leave_date': string | null;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'create_date': string;
    /**
     * 
     * @type {string}
     * @memberof MembersResponse
     */
    'update_date': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PathEnum = {
    BpWorks: 'bp-works',
    Account: 'account'
} as const;

export type PathEnum = typeof PathEnum[keyof typeof PathEnum];


/**
 * 
 * @export
 * @interface PersonalDetailResponse
 */
export interface PersonalDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'project_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'project_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonalDetailResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalDetailResponse
     */
    'work_month': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'work_date': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'emp_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonalDetailResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'note': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalDetailResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface PersonalProjectResponse
 */
export interface PersonalProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'project_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'project_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'emp_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonalProjectResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalProjectResponse
     */
    'work_month': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalProjectResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalProjectResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalProjectResponse
     */
    'operation_rate': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalProjectResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface PersonalSummariesResponse
 */
export interface PersonalSummariesResponse {
    /**
     * 
     * @type {string}
     * @memberof PersonalSummariesResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummariesResponse
     */
    'emp_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummariesResponse
     */
    'dept_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummariesResponse
     */
    'dept_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummariesResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummariesResponse
     */
    'work_month': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummariesResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummariesResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummariesResponse
     */
    'operation_rate': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummariesResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface PersonalSummaryResponse
 */
export interface PersonalSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof PersonalSummaryResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummaryResponse
     */
    'emp_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummaryResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummaryResponse
     */
    'work_month': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummaryResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummaryResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof PersonalSummaryResponse
     */
    'operation_rate': number;
    /**
     * 
     * @type {string}
     * @memberof PersonalSummaryResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface ProjectDetailResponse
 */
export interface ProjectDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'dept_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'dept_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'work_month': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'plan_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'forecast_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'man_hour_progress_rate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'forecast_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDetailResponse
     */
    'forecast_man_hour_use_rate': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface ProjectListResponse
 */
export interface ProjectListResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_rep_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_rep_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_manager_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'project_manager_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'dept_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'dept_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'start_date': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'end_date': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'sales_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'gross_margin_rate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'forecast_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'forecast_gross_margin_rate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'man_hour_progress_rate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'forecast_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponse
     */
    'forecast_man_hour_use_rate': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @interface ProjectMonthlyDetailResponse
 */
export interface ProjectMonthlyDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'job_no': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'project_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'project_name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'project_title': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'dept_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'dept_name': string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'work_year': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'work_month': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'emp_code': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'emp_name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'plan_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'forecast_cost_amnt': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'plan_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'man_hour_progress_rate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'forecast_man_hour': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectMonthlyDetailResponse
     */
    'forecast_man_hour_use_rate': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMonthlyDetailResponse
     */
    'update_date': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleEnum = {
    Admin: 'admin',
    Head: 'head',
    Normal: 'normal',
    NoPermission: 'no_permission'
} as const;

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const UploadFileTypeEnum = {
    Account: 'account',
    BpWorkResults: 'bp_work_results',
    Casting: 'casting',
    EmpWorkResults: 'emp_work_results',
    JapanHoliday: 'japan_holiday',
    Member: 'member',
    Project: 'project'
} as const;

export type UploadFileTypeEnum = typeof UploadFileTypeEnum[keyof typeof UploadFileTypeEnum];


/**
 * 
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    'file_name': string;
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    'file_path': string;
    /**
     * 
     * @type {UploadFileTypeEnum}
     * @memberof UploadResponse
     */
    'file_type': UploadFileTypeEnum;
    /**
     * 
     * @type {UploadStatusEnum}
     * @memberof UploadResponse
     */
    'file_status': UploadStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    'create_date': string;
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    'update_date': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const UploadStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type UploadStatusEnum = typeof UploadStatusEnum[keyof typeof UploadStatusEnum];


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 経理原価一覧取得
         * @summary 経理原価一覧画面
         * @param {string | null} [jobNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCostList: async (jobNo?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/cost-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobNo !== undefined) {
                localVarQueryParameter['job_no'] = jobNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 経理原価一覧取得
         * @summary 経理原価一覧画面
         * @param {string | null} [jobNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountCostList(jobNo?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountCostList(jobNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.getAccountCostList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 経理原価一覧取得
         * @summary 経理原価一覧画面
         * @param {string | null} [jobNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountCostList(jobNo?: string | null, options?: any): AxiosPromise<Array<AccountCostResponse>> {
            return localVarFp.getAccountCostList(jobNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 経理原価一覧取得
     * @summary 経理原価一覧画面
     * @param {string | null} [jobNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountCostList(jobNo?: string | null, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccountCostList(jobNo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MdmApi - axios parameter creator
 * @export
 */
export const MdmApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * BP原価マスタ削除
         * @summary BP原価マスタ管理削除
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBpCost: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteBpCost', 'requestBody', requestBody)
            const localVarPath = `/mdm/manage-bp-cost/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 社員原価マスタ削除
         * @summary 社員原価マスタ管理削除
         * @param {Array<EmployeeCostDeleteRequest>} employeeCostDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployeeCost: async (employeeCostDeleteRequest: Array<EmployeeCostDeleteRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeCostDeleteRequest' is not null or undefined
            assertParamExists('deleteEmployeeCost', 'employeeCostDeleteRequest', employeeCostDeleteRequest)
            const localVarPath = `/mdm/manage-employee-cost/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeCostDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BP原価マスタ取得
         * @summary BP原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpCost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mdm/manage-bp-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 社員原価マスタ取得
         * @summary 社員原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeCost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mdm/manage-employee-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * BP原価マスタ更新
         * @summary BP原価マスタ管理更新
         * @param {Array<ManageBpCostPutRequest>} manageBpCostPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBpCost: async (manageBpCostPutRequest: Array<ManageBpCostPutRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manageBpCostPutRequest' is not null or undefined
            assertParamExists('putBpCost', 'manageBpCostPutRequest', manageBpCostPutRequest)
            const localVarPath = `/mdm/manage-bp-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manageBpCostPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 社員原価マスタ更新
         * @summary 社員原価マスタ管理更新
         * @param {EmployeeCostListPutRequest} employeeCostListPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmployeeCost: async (employeeCostListPutRequest: EmployeeCostListPutRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeCostListPutRequest' is not null or undefined
            assertParamExists('putEmployeeCost', 'employeeCostListPutRequest', employeeCostListPutRequest)
            const localVarPath = `/mdm/manage-employee-cost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeCostListPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MdmApi - functional programming interface
 * @export
 */
export const MdmApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MdmApiAxiosParamCreator(configuration)
    return {
        /**
         * BP原価マスタ削除
         * @summary BP原価マスタ管理削除
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBpCost(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManageBpCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBpCost(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.deleteBpCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 社員原価マスタ削除
         * @summary 社員原価マスタ管理削除
         * @param {Array<EmployeeCostDeleteRequest>} employeeCostDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmployeeCost(employeeCostDeleteRequest: Array<EmployeeCostDeleteRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmployeeCost(employeeCostDeleteRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.deleteEmployeeCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * BP原価マスタ取得
         * @summary BP原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpCost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManageBpCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpCost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.getBpCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 社員原価マスタ取得
         * @summary 社員原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployeeCost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployeeCost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.getEmployeeCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * BP原価マスタ更新
         * @summary BP原価マスタ管理更新
         * @param {Array<ManageBpCostPutRequest>} manageBpCostPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBpCost(manageBpCostPutRequest: Array<ManageBpCostPutRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManageBpCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBpCost(manageBpCostPutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.putBpCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 社員原価マスタ更新
         * @summary 社員原価マスタ管理更新
         * @param {EmployeeCostListPutRequest} employeeCostListPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEmployeeCost(employeeCostListPutRequest: EmployeeCostListPutRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeCostResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEmployeeCost(employeeCostListPutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MdmApi.putEmployeeCost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MdmApi - factory interface
 * @export
 */
export const MdmApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MdmApiFp(configuration)
    return {
        /**
         * BP原価マスタ削除
         * @summary BP原価マスタ管理削除
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBpCost(requestBody: Array<string>, options?: any): AxiosPromise<Array<ManageBpCostResponse>> {
            return localVarFp.deleteBpCost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 社員原価マスタ削除
         * @summary 社員原価マスタ管理削除
         * @param {Array<EmployeeCostDeleteRequest>} employeeCostDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmployeeCost(employeeCostDeleteRequest: Array<EmployeeCostDeleteRequest>, options?: any): AxiosPromise<Array<EmployeeCostResponse>> {
            return localVarFp.deleteEmployeeCost(employeeCostDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * BP原価マスタ取得
         * @summary BP原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpCost(options?: any): AxiosPromise<Array<ManageBpCostResponse>> {
            return localVarFp.getBpCost(options).then((request) => request(axios, basePath));
        },
        /**
         * 社員原価マスタ取得
         * @summary 社員原価マスタ管理取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployeeCost(options?: any): AxiosPromise<Array<EmployeeCostResponse>> {
            return localVarFp.getEmployeeCost(options).then((request) => request(axios, basePath));
        },
        /**
         * BP原価マスタ更新
         * @summary BP原価マスタ管理更新
         * @param {Array<ManageBpCostPutRequest>} manageBpCostPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBpCost(manageBpCostPutRequest: Array<ManageBpCostPutRequest>, options?: any): AxiosPromise<Array<ManageBpCostResponse>> {
            return localVarFp.putBpCost(manageBpCostPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 社員原価マスタ更新
         * @summary 社員原価マスタ管理更新
         * @param {EmployeeCostListPutRequest} employeeCostListPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmployeeCost(employeeCostListPutRequest: EmployeeCostListPutRequest, options?: any): AxiosPromise<Array<EmployeeCostResponse>> {
            return localVarFp.putEmployeeCost(employeeCostListPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MdmApi - object-oriented interface
 * @export
 * @class MdmApi
 * @extends {BaseAPI}
 */
export class MdmApi extends BaseAPI {
    /**
     * BP原価マスタ削除
     * @summary BP原価マスタ管理削除
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public deleteBpCost(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).deleteBpCost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 社員原価マスタ削除
     * @summary 社員原価マスタ管理削除
     * @param {Array<EmployeeCostDeleteRequest>} employeeCostDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public deleteEmployeeCost(employeeCostDeleteRequest: Array<EmployeeCostDeleteRequest>, options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).deleteEmployeeCost(employeeCostDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BP原価マスタ取得
     * @summary BP原価マスタ管理取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public getBpCost(options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).getBpCost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 社員原価マスタ取得
     * @summary 社員原価マスタ管理取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public getEmployeeCost(options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).getEmployeeCost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * BP原価マスタ更新
     * @summary BP原価マスタ管理更新
     * @param {Array<ManageBpCostPutRequest>} manageBpCostPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public putBpCost(manageBpCostPutRequest: Array<ManageBpCostPutRequest>, options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).putBpCost(manageBpCostPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 社員原価マスタ更新
     * @summary 社員原価マスタ管理更新
     * @param {EmployeeCostListPutRequest} employeeCostListPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MdmApi
     */
    public putEmployeeCost(employeeCostListPutRequest: EmployeeCostListPutRequest, options?: RawAxiosRequestConfig) {
        return MdmApiFp(this.configuration).putEmployeeCost(employeeCostListPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MembersApi - axios parameter creator
 * @export
 */
export const MembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メンバー登録
         * @summary メンバー登録
         * @param {MemberAddRequest} memberAddRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMember: async (memberAddRequest: MemberAddRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberAddRequest' is not null or undefined
            assertParamExists('addMember', 'memberAddRequest', memberAddRequest)
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberAddRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー削除
         * @summary メンバー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteMember', 'userId', userId)
            const localVarPath = `/members/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー一覧取得
         * @summary メンバー一覧取得
         * @param {boolean} [showAdditionalLoginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersList: async (showAdditionalLoginInfo?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (showAdditionalLoginInfo !== undefined) {
                localVarQueryParameter['show_additional_login_info'] = showAdditionalLoginInfo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メンバー更新
         * @summary メンバー更新
         * @param {MemberUpdateRequest} memberUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember: async (memberUpdateRequest: MemberUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberUpdateRequest' is not null or undefined
            assertParamExists('updateMember', 'memberUpdateRequest', memberUpdateRequest)
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MembersApi - functional programming interface
 * @export
 */
export const MembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MembersApiAxiosParamCreator(configuration)
    return {
        /**
         * メンバー登録
         * @summary メンバー登録
         * @param {MemberAddRequest} memberAddRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMember(memberAddRequest: MemberAddRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MembersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMember(memberAddRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembersApi.addMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * メンバー削除
         * @summary メンバー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMember(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MembersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMember(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembersApi.deleteMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * メンバー一覧取得
         * @summary メンバー一覧取得
         * @param {boolean} [showAdditionalLoginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembersList(showAdditionalLoginInfo?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MembersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembersList(showAdditionalLoginInfo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembersApi.getMembersList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * メンバー更新
         * @summary メンバー更新
         * @param {MemberUpdateRequest} memberUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMember(memberUpdateRequest: MemberUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MembersResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(memberUpdateRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MembersApi.updateMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MembersApi - factory interface
 * @export
 */
export const MembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MembersApiFp(configuration)
    return {
        /**
         * メンバー登録
         * @summary メンバー登録
         * @param {MemberAddRequest} memberAddRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMember(memberAddRequest: MemberAddRequest, options?: any): AxiosPromise<Array<MembersResponse>> {
            return localVarFp.addMember(memberAddRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー削除
         * @summary メンバー削除
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember(userId: string, options?: any): AxiosPromise<Array<MembersResponse>> {
            return localVarFp.deleteMember(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー一覧取得
         * @summary メンバー一覧取得
         * @param {boolean} [showAdditionalLoginInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembersList(showAdditionalLoginInfo?: boolean, options?: any): AxiosPromise<Array<MembersResponse>> {
            return localVarFp.getMembersList(showAdditionalLoginInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * メンバー更新
         * @summary メンバー更新
         * @param {MemberUpdateRequest} memberUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMember(memberUpdateRequest: MemberUpdateRequest, options?: any): AxiosPromise<Array<MembersResponse>> {
            return localVarFp.updateMember(memberUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MembersApi - object-oriented interface
 * @export
 * @class MembersApi
 * @extends {BaseAPI}
 */
export class MembersApi extends BaseAPI {
    /**
     * メンバー登録
     * @summary メンバー登録
     * @param {MemberAddRequest} memberAddRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public addMember(memberAddRequest: MemberAddRequest, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).addMember(memberAddRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー削除
     * @summary メンバー削除
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public deleteMember(userId: string, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).deleteMember(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー一覧取得
     * @summary メンバー一覧取得
     * @param {boolean} [showAdditionalLoginInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public getMembersList(showAdditionalLoginInfo?: boolean, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).getMembersList(showAdditionalLoginInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メンバー更新
     * @summary メンバー更新
     * @param {MemberUpdateRequest} memberUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MembersApi
     */
    public updateMember(memberUpdateRequest: MemberUpdateRequest, options?: RawAxiosRequestConfig) {
        return MembersApiFp(this.configuration).updateMember(memberUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonalApi - axios parameter creator
 * @export
 */
export const PersonalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 個人工数詳細_工数一覧取得
         * @summary 個人工数詳細_工数一覧画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalDetail: async (empCode: string, year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empCode' is not null or undefined
            assertParamExists('getPersonalDetail', 'empCode', empCode)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPersonalDetail', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPersonalDetail', 'month', month)
            const localVarPath = `/personal/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (empCode !== undefined) {
                localVarQueryParameter['emp_code'] = empCode;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個人工数詳細_案件詳細取得
         * @summary 個人工数詳細_案件詳細画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalProject: async (empCode: string, year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empCode' is not null or undefined
            assertParamExists('getPersonalProject', 'empCode', empCode)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPersonalProject', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPersonalProject', 'month', month)
            const localVarPath = `/personal/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (empCode !== undefined) {
                localVarQueryParameter['emp_code'] = empCode;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個人工数集計一覧取得
         * @summary 個人工数集計一覧画面
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalSummaries: async (year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPersonalSummaries', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getPersonalSummaries', 'month', month)
            const localVarPath = `/personal/summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 個人工数集計取得
         * @summary 個人工数集計画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number | null} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalSummary: async (empCode: string, year: number, month?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empCode' is not null or undefined
            assertParamExists('getPersonalSummary', 'empCode', empCode)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPersonalSummary', 'year', year)
            const localVarPath = `/personal/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (empCode !== undefined) {
                localVarQueryParameter['emp_code'] = empCode;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalApi - functional programming interface
 * @export
 */
export const PersonalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalApiAxiosParamCreator(configuration)
    return {
        /**
         * 個人工数詳細_工数一覧取得
         * @summary 個人工数詳細_工数一覧画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalDetail(empCode: string, year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonalDetailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalDetail(empCode, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonalApi.getPersonalDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 個人工数詳細_案件詳細取得
         * @summary 個人工数詳細_案件詳細画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalProject(empCode: string, year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonalProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalProject(empCode, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonalApi.getPersonalProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 個人工数集計一覧取得
         * @summary 個人工数集計一覧画面
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalSummaries(year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonalSummariesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalSummaries(year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonalApi.getPersonalSummaries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 個人工数集計取得
         * @summary 個人工数集計画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number | null} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalSummary(empCode: string, year: number, month?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonalSummaryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalSummary(empCode, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonalApi.getPersonalSummary']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PersonalApi - factory interface
 * @export
 */
export const PersonalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalApiFp(configuration)
    return {
        /**
         * 個人工数詳細_工数一覧取得
         * @summary 個人工数詳細_工数一覧画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalDetail(empCode: string, year: number, month: number, options?: any): AxiosPromise<Array<PersonalDetailResponse>> {
            return localVarFp.getPersonalDetail(empCode, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 個人工数詳細_案件詳細取得
         * @summary 個人工数詳細_案件詳細画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalProject(empCode: string, year: number, month: number, options?: any): AxiosPromise<Array<PersonalProjectResponse>> {
            return localVarFp.getPersonalProject(empCode, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 個人工数集計一覧取得
         * @summary 個人工数集計一覧画面
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalSummaries(year: number, month: number, options?: any): AxiosPromise<Array<PersonalSummariesResponse>> {
            return localVarFp.getPersonalSummaries(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 個人工数集計取得
         * @summary 個人工数集計画面
         * @param {string} empCode 
         * @param {number} year 
         * @param {number | null} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalSummary(empCode: string, year: number, month?: number | null, options?: any): AxiosPromise<Array<PersonalSummaryResponse>> {
            return localVarFp.getPersonalSummary(empCode, year, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalApi - object-oriented interface
 * @export
 * @class PersonalApi
 * @extends {BaseAPI}
 */
export class PersonalApi extends BaseAPI {
    /**
     * 個人工数詳細_工数一覧取得
     * @summary 個人工数詳細_工数一覧画面
     * @param {string} empCode 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApi
     */
    public getPersonalDetail(empCode: string, year: number, month: number, options?: RawAxiosRequestConfig) {
        return PersonalApiFp(this.configuration).getPersonalDetail(empCode, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個人工数詳細_案件詳細取得
     * @summary 個人工数詳細_案件詳細画面
     * @param {string} empCode 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApi
     */
    public getPersonalProject(empCode: string, year: number, month: number, options?: RawAxiosRequestConfig) {
        return PersonalApiFp(this.configuration).getPersonalProject(empCode, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個人工数集計一覧取得
     * @summary 個人工数集計一覧画面
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApi
     */
    public getPersonalSummaries(year: number, month: number, options?: RawAxiosRequestConfig) {
        return PersonalApiFp(this.configuration).getPersonalSummaries(year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 個人工数集計取得
     * @summary 個人工数集計画面
     * @param {string} empCode 
     * @param {number} year 
     * @param {number | null} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalApi
     */
    public getPersonalSummary(empCode: string, year: number, month?: number | null, options?: RawAxiosRequestConfig) {
        return PersonalApiFp(this.configuration).getPersonalSummary(empCode, year, month, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 案件収支詳細取得
         * @summary 案件収支詳細画面
         * @param {string} jobNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetail: async (jobNo: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobNo' is not null or undefined
            assertParamExists('getProjectDetail', 'jobNo', jobNo)
            const localVarPath = `/project/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobNo !== undefined) {
                localVarQueryParameter['job_no'] = jobNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件別収支一覧取得
         * @summary 案件別収支一覧画面
         * @param {number} fromYear 
         * @param {number} fromMonth 
         * @param {number} toYear 
         * @param {number} toMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectList: async (fromYear: number, fromMonth: number, toYear: number, toMonth: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromYear' is not null or undefined
            assertParamExists('getProjectList', 'fromYear', fromYear)
            // verify required parameter 'fromMonth' is not null or undefined
            assertParamExists('getProjectList', 'fromMonth', fromMonth)
            // verify required parameter 'toYear' is not null or undefined
            assertParamExists('getProjectList', 'toYear', toYear)
            // verify required parameter 'toMonth' is not null or undefined
            assertParamExists('getProjectList', 'toMonth', toMonth)
            const localVarPath = `/project/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromYear !== undefined) {
                localVarQueryParameter['from_year'] = fromYear;
            }

            if (fromMonth !== undefined) {
                localVarQueryParameter['from_month'] = fromMonth;
            }

            if (toYear !== undefined) {
                localVarQueryParameter['to_year'] = toYear;
            }

            if (toMonth !== undefined) {
                localVarQueryParameter['to_month'] = toMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 月別案件収支詳細取得
         * @summary 月別案件収支詳細画面
         * @param {string} jobNo 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMonthlyDetail: async (jobNo: string, year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobNo' is not null or undefined
            assertParamExists('getProjectMonthlyDetail', 'jobNo', jobNo)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getProjectMonthlyDetail', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getProjectMonthlyDetail', 'month', month)
            const localVarPath = `/project/monthly-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobNo !== undefined) {
                localVarQueryParameter['job_no'] = jobNo;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 案件収支詳細取得
         * @summary 案件収支詳細画面
         * @param {string} jobNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectDetail(jobNo: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectDetailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectDetail(jobNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.getProjectDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件別収支一覧取得
         * @summary 案件別収支一覧画面
         * @param {number} fromYear 
         * @param {number} fromMonth 
         * @param {number} toYear 
         * @param {number} toMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectList(fromYear: number, fromMonth: number, toYear: number, toMonth: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectList(fromYear, fromMonth, toYear, toMonth, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.getProjectList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 月別案件収支詳細取得
         * @summary 月別案件収支詳細画面
         * @param {string} jobNo 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectMonthlyDetail(jobNo: string, year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectMonthlyDetailResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectMonthlyDetail(jobNo, year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.getProjectMonthlyDetail']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 案件収支詳細取得
         * @summary 案件収支詳細画面
         * @param {string} jobNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectDetail(jobNo: string, options?: any): AxiosPromise<Array<ProjectDetailResponse>> {
            return localVarFp.getProjectDetail(jobNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件別収支一覧取得
         * @summary 案件別収支一覧画面
         * @param {number} fromYear 
         * @param {number} fromMonth 
         * @param {number} toYear 
         * @param {number} toMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectList(fromYear: number, fromMonth: number, toYear: number, toMonth: number, options?: any): AxiosPromise<Array<ProjectListResponse>> {
            return localVarFp.getProjectList(fromYear, fromMonth, toYear, toMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 月別案件収支詳細取得
         * @summary 月別案件収支詳細画面
         * @param {string} jobNo 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMonthlyDetail(jobNo: string, year: number, month: number, options?: any): AxiosPromise<Array<ProjectMonthlyDetailResponse>> {
            return localVarFp.getProjectMonthlyDetail(jobNo, year, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 案件収支詳細取得
     * @summary 案件収支詳細画面
     * @param {string} jobNo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectDetail(jobNo: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectDetail(jobNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件別収支一覧取得
     * @summary 案件別収支一覧画面
     * @param {number} fromYear 
     * @param {number} fromMonth 
     * @param {number} toYear 
     * @param {number} toMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectList(fromYear: number, fromMonth: number, toYear: number, toMonth: number, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectList(fromYear, fromMonth, toYear, toMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 月別案件収支詳細取得
     * @summary 月別案件収支詳細画面
     * @param {string} jobNo 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectMonthlyDetail(jobNo: string, year: number, month: number, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectMonthlyDetail(jobNo, year, month, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * BP工数アップロードファイルリスト
         * @summary BP工数アップロードファイルリスト
         * @param {UploadFileTypeEnum} [fileType] 
         * @param {string | null} [fromUpdateDate] 検索開始日
         * @param {string | null} [toUpdateDate] 検索終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpWorksFilesList: async (fileType?: UploadFileTypeEnum, fromUpdateDate?: string | null, toUpdateDate?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload/bp-works/files/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileType !== undefined) {
                localVarQueryParameter['file_type'] = fileType;
            }

            if (fromUpdateDate !== undefined) {
                localVarQueryParameter['from_update_date'] = (fromUpdateDate as any instanceof Date) ?
                    (fromUpdateDate as any).toISOString().substring(0,10) :
                    fromUpdateDate;
            }

            if (toUpdateDate !== undefined) {
                localVarQueryParameter['to_update_date'] = (toUpdateDate as any instanceof Date) ?
                    (toUpdateDate as any).toISOString().substring(0,10) :
                    toUpdateDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ファイルアップロード
         * @summary ファイルアップロード
         * @param {PathEnum} path 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile: async (path: PathEnum, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('postFile', 'path', path)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postFile', 'file', file)
            const localVarPath = `/upload/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * BP工数アップロードファイルリスト
         * @summary BP工数アップロードファイルリスト
         * @param {UploadFileTypeEnum} [fileType] 
         * @param {string | null} [fromUpdateDate] 検索開始日
         * @param {string | null} [toUpdateDate] 検索終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBpWorksFilesList(fileType?: UploadFileTypeEnum, fromUpdateDate?: string | null, toUpdateDate?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UploadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBpWorksFilesList(fileType, fromUpdateDate, toUpdateDate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UploadApi.getBpWorksFilesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ファイルアップロード
         * @summary ファイルアップロード
         * @param {PathEnum} path 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFile(path: PathEnum, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFile(path, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UploadApi.postFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * BP工数アップロードファイルリスト
         * @summary BP工数アップロードファイルリスト
         * @param {UploadFileTypeEnum} [fileType] 
         * @param {string | null} [fromUpdateDate] 検索開始日
         * @param {string | null} [toUpdateDate] 検索終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBpWorksFilesList(fileType?: UploadFileTypeEnum, fromUpdateDate?: string | null, toUpdateDate?: string | null, options?: any): AxiosPromise<Array<UploadResponse>> {
            return localVarFp.getBpWorksFilesList(fileType, fromUpdateDate, toUpdateDate, options).then((request) => request(axios, basePath));
        },
        /**
         * ファイルアップロード
         * @summary ファイルアップロード
         * @param {PathEnum} path 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile(path: PathEnum, file: File, options?: any): AxiosPromise<void> {
            return localVarFp.postFile(path, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * BP工数アップロードファイルリスト
     * @summary BP工数アップロードファイルリスト
     * @param {UploadFileTypeEnum} [fileType] 
     * @param {string | null} [fromUpdateDate] 検索開始日
     * @param {string | null} [toUpdateDate] 検索終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getBpWorksFilesList(fileType?: UploadFileTypeEnum, fromUpdateDate?: string | null, toUpdateDate?: string | null, options?: RawAxiosRequestConfig) {
        return UploadApiFp(this.configuration).getBpWorksFilesList(fileType, fromUpdateDate, toUpdateDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ファイルアップロード
     * @summary ファイルアップロード
     * @param {PathEnum} path 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public postFile(path: PathEnum, file: File, options?: RawAxiosRequestConfig) {
        return UploadApiFp(this.configuration).postFile(path, file, options).then((request) => request(this.axios, this.basePath));
    }
}



