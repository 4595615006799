import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { CostAccountingList } from '../../../domain/cost-accounting';
import { getAccountCostList } from '../../../helpers/cost-accounting';
import { costAccountingJobNoState } from '../../../recoil/atoms/cost-accounting';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import CostAccountingTable from './ListTable';

function CostAccounting() {
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head]);
  const [showErrorNotification] = useErrorNotification();
  const [loading, setLoading] = useState(false);
  const [costAccountingList, setCostAccountingList] = useState<
    CostAccountingList[]
  >([]);
  const jobNo = useRecoilValue<string>(costAccountingJobNoState);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const accountCostList = await getAccountCostList(jobNo);
        setCostAccountingList(accountCostList);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [jobNo]);

  return (
    <CostAccountingTable
      costAccountingList={costAccountingList}
      loading={loading}
    />
  );
}

export default CostAccounting;
