import { Auth } from 'aws-amplify';
import { selector } from 'recoil';

import { sessionAtom } from '../atoms/auth';

export const sessionSelector = selector({
  key: 'sessionSelector',
  get: async () => {
    try {
      return (await Auth.currentSession()).getIdToken();
    } catch (error) {
      return null;
    }
  },
  set: ({ set }, newValue) => {
    set(sessionAtom, newValue);
  },
});
