import { useState } from 'react';

import { EditBpCost } from '../../../domain/manage-cost';
import { table, uniqueByColumn } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { DeleteButton, EditableTable, ReflectButton } from '../../common';
import { EditableColumnsType } from '../../common/EditableTable';
import { getColumnSearchProps } from '../../common/TableFilter';

interface Props {
  manageBpCostList: EditBpCost[];
  loading?: boolean;
  onEdit: (row: EditBpCost, columnName: keyof EditBpCost) => void;
  onSave: () => Promise<void>;
  onDelete: (rows: EditBpCost[]) => Promise<void>;
}

const TABLE_HEADER_HEIGHT = 62;
const TABLE_TOOLBAR_HEIGHT = 44;

export default function BpEditTable({
  manageBpCostList,
  loading = false,
  onEdit,
  onSave,
  onDelete,
}: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleDelete = async () => {
    const employee = manageBpCostList.filter((bp) =>
      selectedRowKeys.find((key) => key === bp.bpNo)
    );
    if (employee === undefined) throw new Error('Employee not found');
    setSelectedRowKeys([]);
    onDelete(employee);
  };

  const columnSettings: EditableColumnsType<EditBpCost>[] = [
    {
      title: '変更',
      dataIndex: 'edited',
      width: 50,
      render: (value: boolean) => (value ? '*' : ''),
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'BPコード',
      dataIndex: 'bpNo',
      defaultSortOrder: 'descend',
      sorter: (a: EditBpCost, b: EditBpCost) =>
        table.descendingComparator(a, b, 'bpNo'),
      ...getColumnSearchProps('bpNo', 'BPコード'),
      width: 120,
      fixed: 'left',
      align: 'center',
    },
    {
      title: '外注先名',
      dataIndex: 'outsourceName',
      fixed: 'left',
      width: 160,
      sorter: (a, b) => table.descendingComparator(a, b, 'outsourceName'),
      ...getColumnSearchProps(
        'outsourceName',
        '外注先名',
        uniqueByColumn(manageBpCostList, 'outsourceName').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: 'BP氏名',
      dataIndex: 'bpName',
      fixed: 'left',
      width: 120,
      sorter: (a, b) => table.descendingComparator(a, b, 'bpName'),
      ...getColumnSearchProps(
        'bpName',
        'BP氏名',
        uniqueByColumn(manageBpCostList, 'bpName').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: '受入部署',
      dataIndex: 'acceptDept',
      width: 200,
      editableCellCss: classes.editableCellValue,
      editable: true,
    },
    {
      title: 'BeeX管理者',
      dataIndex: 'beexRepName',
      width: 120,
      editableCellCss: classes.editableCellValue,
      editable: true,
    },
    {
      title: '外注先責任者',
      dataIndex: 'outsourceRepName',
      width: 120,
      editableCellCss: classes.editableCellValue,
      editable: true,
    },
    {
      title: '契約開始日',
      dataIndex: 'contractStartDate',
      width: 120,
      editableCellCss: classes.editableCellValue,
      editable: true,
    },
    {
      title: '契約終了日',
      dataIndex: 'contractEndDate',
      width: 120,
      editableCellCss: classes.editableCellValue,
      editable: true,
    },
    {
      title: '標準原価',
      dataIndex: 'stdCostAmnt',
      width: 150,
      editableCellCss: classes.editableCellNumberValue,
      editable: true,
      rules: [
        {
          required: true,
          message: '標準原価を入力してください',
        },
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
    },
    {
      title: '契約\n減少時間',
      dataIndex: 'contractReduceTime',
      width: 100,
      editableCellCss: classes.editableCellNumberValue,
      editable: true,
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
    },
    {
      title: '契約\n超過時間',
      dataIndex: 'contractOverTime',
      width: 100,
      editableCellCss: classes.editableCellNumberValue,
      editable: true,
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
    },
    {
      title: '契約\n減少金額',
      dataIndex: 'contractReduceAmnt',
      width: 120,
      editableCellCss: classes.editableCellNumberValue,
      editable: true,
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
    },
    {
      title: '契約\n超過金額',
      dataIndex: 'contractOverAmnt',
      width: 120,
      editableCellCss: classes.editableCellNumberValue,
      editable: true,
      rules: [
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      width: 130,
    },
  ];

  return (
    <EditableTable<EditBpCost>
      editableColumns={columnSettings}
      rowKey="bpNo"
      dataSource={manageBpCostList}
      loading={loading}
      onEdit={onEdit}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
          setSelectedRowKeys(newSelectedRowKeys);
        },
      }}
      heightSize={TABLE_HEADER_HEIGHT + TABLE_TOOLBAR_HEIGHT}
      toolBar={
        <div className={classes.tableToolbar}>
          <ReflectButton
            disabled={manageBpCostList.filter((bp) => bp.edited).length === 0}
            onConfirm={onSave}
          >
            データを反映
          </ReflectButton>
          <DeleteButton
            onConfirm={handleDelete}
            disabled={selectedRowKeys.length === 0}
          />
        </div>
      }
    />
  );
}
