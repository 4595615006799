import { InboxOutlined } from '@ant-design/icons';
import { Flex, Button, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';

import classes from '../../styles/common.module.scss';

const { Dragger } = Upload;

interface Props {
  onUpload: (file: RcFile) => Promise<void>;
}

export default function FileUpload({ onUpload }: Props) {
  const [file, setFile] = useState<RcFile | null>(null);
  const [uploading, isUploading] = useState<boolean>(false);

  const handleUpload = async () => {
    isUploading(true);
    if (file === null) return;
    await onUpload(file);
    isUploading(false);
  };

  return (
    <>
      <Dragger
        beforeUpload={(f) => {
          setFile(f);
          return false;
        }}
        fileList={file ? [file] : []}
        onRemove={() => setFile(null)}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          インポートするファイル（Excel）をドラッグ&ドロップ、もしくはクリックしてください。
        </p>
      </Dragger>
      <Flex align="center" vertical className={classes.heightSpace}>
        <Button
          type="primary"
          size="large"
          onClick={handleUpload}
          disabled={file === null}
          loading={uploading}
        >
          アップロード
        </Button>
      </Flex>
    </>
  );
}
