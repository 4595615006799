import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

import {
  EditEmployeeCost,
  EmployeeCost,
  SaveEmployeeCost,
  BpCost,
  EditBpCost,
  SaveBpCost,
} from '../domain/manage-cost';
import { api } from '../lib';

export const getEmployeeList = async () => {
  const employeeList = await api.mdmApi.getEmployeeCost().then(
    (res) =>
      camelcaseKeys(res.data, {
        deep: true,
      }) as EmployeeCost[]
  );
  return employeeList.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
    key: `${emp.hqCode}${emp.deptCode}${emp.groupCode}${emp.jobPosition}${emp.zone}`,
  }));
};

export const saveEmployeeList = async (employeeList: SaveEmployeeCost[]) => {
  const results = await api.mdmApi
    .putEmployeeCost({
      employee_cost_list: snakecaseKeys(employeeList, { deep: true }),
    })
    .then(
      (res) =>
        camelcaseKeys(res.data, {
          deep: true,
        }) as EmployeeCost[]
    );
  return results.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
    key: `${emp.hqCode}${emp.deptCode}${emp.groupCode}${emp.jobPosition}${emp.zone}`,
  }));
};

export const deleteEmployeeList = async (employeeList: EditEmployeeCost[]) => {
  const results = await api.mdmApi
    .deleteEmployeeCost(
      employeeList.map((employee) => ({
        hq_code: employee.hqCode,
        dept_code: employee.deptCode,
        group_code: employee.groupCode,
        job_position: employee.jobPosition,
        zone: employee.zone,
      }))
    )
    .then(
      (res) =>
        camelcaseKeys(res.data, {
          deep: true,
        }) as EmployeeCost[]
    );
  return results.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
    key: `${emp.hqCode}${emp.deptCode}${emp.groupCode}${emp.jobPosition}${emp.zone}`,
  }));
};

export const getBpCostList = async () => {
  const bpCostList = await api.mdmApi.getBpCost().then(
    (res) =>
      camelcaseKeys(res.data, {
        deep: true,
      }) as BpCost[]
  );
  return bpCostList.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
  }));
};

export const saveBpCostList = async (boCostList: SaveBpCost[]) => {
  const results = await api.mdmApi
    .putBpCost(snakecaseKeys(boCostList, { deep: true }))
    .then(
      (res) =>
        camelcaseKeys(res.data, {
          deep: true,
        }) as EditBpCost[]
    );
  return results.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
  }));
};

export const deleteBpList = async (bpList: EditBpCost[]) => {
  const results = await api.mdmApi
    .deleteBpCost(bpList.map((bp) => bp.bpNo))
    .then(
      (res) =>
        camelcaseKeys(res.data, {
          deep: true,
        }) as BpCost[]
    );
  return results.map((emp) => ({
    ...emp,
    updateDate: new Date(emp.updateDate).toLocaleString(),
  }));
};
