import { ColumnsType } from 'antd/lib/table';

import { CostAccountingList } from '../../../domain/cost-accounting';
import { table, uniqueByColumn } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { getColumnSearchProps } from '../../common';
import Table from '../../common/Table';
import { TableNumberFilterProps } from '../../common/TableNumberFilter';

interface Props {
  costAccountingList: CostAccountingList[];
  loading: boolean;
}

const TABLE_HEADER_HEIGHT = 62;

export default function CostAccountingTable({
  costAccountingList,
  loading,
}: Props) {
  const columns: ColumnsType<CostAccountingList> = [
    {
      title: 'PJ番号',
      dataIndex: 'projectCode',
      key: 'projectCode',
      width: 100,
      fixed: 'left',
      ...getColumnSearchProps(
        'projectCode',
        'PJ番号',
        uniqueByColumn(costAccountingList, 'projectCode').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectCode'),
    },
    {
      title: 'JobNo',
      dataIndex: 'jobNo',
      key: 'jobNo',
      width: 100,
      fixed: 'left',
      ...getColumnSearchProps(
        'jobNo',
        'JobNo',
        uniqueByColumn(costAccountingList, 'jobNo').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'jobNo'),
    },
    {
      title: '案件名',
      dataIndex: 'projectTitle',
      key: 'projectTitle',
      width: 300,
      fixed: 'left',
      ...getColumnSearchProps(
        'projectTitle',
        '案件名',
        uniqueByColumn(costAccountingList, 'projectTitle').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectTitle'),
    },
    {
      title: '担当者名',
      dataIndex: 'projectRepName',
      key: 'projectRepName',
      width: 130,
      ...getColumnSearchProps(
        'projectRepName',
        '担当者名',
        uniqueByColumn(costAccountingList, 'projectRepName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectRepName'),
    },
    {
      title: 'PM/PL',
      dataIndex: 'projectManagerName',
      key: 'projectManagerName',
      width: 130,
      ...getColumnSearchProps(
        'projectManagerName',
        'PM/PL',
        uniqueByColumn(costAccountingList, 'projectManagerName').flatMap(
          (val) => {
            return val !== null ? { text: val, value: val } : [];
          }
        )
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectManagerName'),
    },
    {
      title: '売上金額\n(円)',
      dataIndex: 'projectSalesAmnt',
      key: 'projectSalesAmnt',
      width: 110,
      align: 'right',
      ...TableNumberFilterProps('projectSalesAmnt'),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectSalesAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '標準原価\n(円)',
      dataIndex: 'projectCostAmnt',
      key: 'projectCostAmnt',
      width: 110,
      align: 'right',
      ...TableNumberFilterProps('projectCostAmnt'),
      sorter: (a, b) => table.descendingComparator(a, b, 'projectCostAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '粗利率',
      dataIndex: 'grossMarginRate',
      key: 'grossMarginRate',
      width: 90,
      align: 'right',
      ...TableNumberFilterProps('grossMarginRate'),
      sorter: (a, b) => table.descendingComparator(a, b, 'grossMarginRate'),
      render: (value: number) => `${value ?? '0.00'} %`,
      onCell: ({ grossMarginRate }) => ({
        className: (grossMarginRate ?? 0) < 20 ? classes.dangerColor : '',
      }),
    },
    {
      title: '経理売上_累計\n(円)',
      dataIndex: 'accountSalesAmnt',
      key: 'accountSalesAmnt',
      width: 130,
      align: 'right',
      ...TableNumberFilterProps('accountSalesAmnt'),
      sorter: (a, b) => table.descendingComparator(a, b, 'accountSalesAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '経理原価\n(円)',
      dataIndex: 'accountCostAmnt',
      key: 'accountCostAmnt',
      width: 110,
      align: 'right',
      ...TableNumberFilterProps('accountCostAmnt'),
      sorter: (a, b) => table.descendingComparator(a, b, 'accountCostAmnt'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '利益率',
      dataIndex: 'accountGrossRate',
      key: 'accountGrossRate',
      width: 90,
      align: 'right',
      ...TableNumberFilterProps('accountGrossRate'),
      sorter: (a, b) => table.descendingComparator(a, b, 'accountGrossRate'),
      onCell: ({ accountGrossRate }) => ({
        className: (accountGrossRate ?? 0) < 10 ? classes.dangerColor : '',
      }),
      render: (value: number) => `${value ?? '0.00'} %`,
    },
    {
      title: '標準原価と\nの差分(円)',
      dataIndex: 'diffAccountCost',
      key: 'diffAccountCost',
      width: 110,
      align: 'right',
      ...TableNumberFilterProps('diffAccountCost'),
      sorter: (a, b) => table.descendingComparator(a, b, 'diffAccountCost'),
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      key: 'updateDate',
      width: 100,
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  return (
    <Table<CostAccountingList>
      rowKey="jobNo"
      dataSource={costAccountingList}
      columns={columns}
      heightSize={TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
