import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { PersonalSummaries } from '../../../domain/man-hours';
import { table, uniqueByColumn } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { getColumnSearchProps } from '../../common';
import Table from '../../common/Table';

import { detailLink } from './ManHoursCommon';

interface Props {
  personalMh: PersonalSummaries[];
  searchHeight: number;
  loading: boolean;
}

const TABLE_HEADER_HEIGHT = 62;

export default function ManHoursTotalListTable({
  personalMh,
  searchHeight,
  loading,
}: Props) {
  const columns: ColumnsType<PersonalSummaries> = [
    {
      title: '社員番号',
      dataIndex: 'empCode',
      width: 120,
      fixed: 'left',
      ...getColumnSearchProps(
        'empCode',
        '社員番号',
        uniqueByColumn(personalMh, 'empCode').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'empCode'),
    },
    {
      title: '氏名',
      dataIndex: 'empName',
      width: 130,
      fixed: 'left',
      ...getColumnSearchProps(
        'empName',
        '氏名',
        uniqueByColumn(personalMh, 'empName').flatMap((val) => {
          return val !== null ? { text: val, value: val } : [];
        })
      ),
      sorter: (a, b) => table.descendingComparator(a, b, 'empName'),
      render: (text, { empCode }) => {
        return <Link to={`/man-hours-total/${empCode}`}>{text}</Link>;
      },
    },
    {
      title: '部門名',
      dataIndex: 'deptName',
      width: 190,
      sorter: (a, b) => table.descendingComparator(a, b, 'deptName'),
    },
    {
      title: '予定工数\n(時間)',
      dataIndex: 'planManHour',
      width: 100,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
    },
    {
      title: '実績工数\n(時間)',
      dataIndex: 'manHour',
      width: 100,
      align: 'right',
      sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
    },
    {
      title: '稼働率',
      dataIndex: 'operationRate',
      width: 100,
      align: 'right',
      render: (value: number) => `${value ?? '0.00'} %`,
      sorter: (a, b) => table.descendingComparator(a, b, 'operationRate'),
      onCell: ({ operationRate }) => ({
        className: (operationRate ?? 0) > 100 ? classes.dangerColor : '',
      }),
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      align: 'center',
      width: 100,
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
    {
      title: '',
      width: 175,
      render: (_text, record) => (
        <>{detailLink(record.workYear, record.workMonth, record.empCode)}</>
      ),
    },
  ];
  return (
    <Table<PersonalSummaries>
      rowKey={(record) => `${record.empCode}`}
      dataSource={personalMh}
      columns={columns}
      loading={loading}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
    />
  );
}
