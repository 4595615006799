import { Table as AntTable } from 'antd';
import { ColumnsType, TableProps as AntdTableProps } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { useState } from 'react';

import { SYSTEM_HIGHT } from '../../constants/common';
import classes from '../../styles/table.module.scss';

export interface TableProps<T> extends AntdTableProps<T> {
  dataSource: T[];
  columns: ColumnsType<T>;
  heightSize?: number;
  loading?: boolean;
  toolBar?: JSX.Element;
  rowSelection?: TableRowSelection<T>;
}

const { MAIN_PADDING, PAGINATION, TABLE_PADDING, SCROLL_BAR } = SYSTEM_HIGHT;

export default function Table<T extends object>(props: TableProps<T>) {
  const [pageSize, setPageSize] = useState(50);
  const {
    dataSource,
    columns,
    rowKey,
    heightSize,
    loading = false,
    toolBar,
    rowSelection,
  } = props;

  const scrollSize = [
    MAIN_PADDING,
    PAGINATION,
    TABLE_PADDING,
    SCROLL_BAR,
  ].reduce((a, b) => a + b, 0);

  const onShowSizeChange = (_: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div className={classes.tableLayout}>
      {toolBar}
      <AntTable
        {...props}
        bordered
        size="small"
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey as string}
        pagination={{
          pageSize,
          onShowSizeChange,
          showTotal: (total: number) => `Total ${total} items`,
          style: { margin: '12px 0 0 0' },
          pageSizeOptions: [50, 100, 200],
        }}
        scroll={{
          y: `calc(100vh - ${scrollSize + (heightSize ?? 0)}px)`,
        }}
        rowSelection={rowSelection}
        loading={loading}
        showSorterTooltip={false}
      />
    </div>
  );
}
