import { Button, Form, FormInstance, Input, InputRef } from 'antd';
import { Rule } from 'antd/es/form';
import { ColumnType } from 'antd/es/table';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import Table, { TableProps } from './Table';

const EditableContext = createContext<FormInstance | null>(null);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function EditableRow({ index, ...props }: { index: number }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

interface EditableCellProps<Item> {
  title: React.ReactNode;
  rules: Rule[];
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item, columnName: string) => void;
  editableCellCss?: string;
}

function EditableCell<Item>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  title,
  rules,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  editableCellCss,
  ...restProps
}: EditableCellProps<Item>) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  if (form == null) throw new Error('EditableContext is null');

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    const values = await form.validateFields();

    toggleEdit();
    if (record[dataIndex] !== values[dataIndex])
      handleSave({ ...record, ...values }, dataIndex as string);
  };

  if (editable !== true) return <td {...restProps}>{children}</td>;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex.toString()}
          rules={rules}
          initialValue={record[dataIndex]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <Button type="default" onClick={toggleEdit} className={editableCellCss}>
          {children}
        </Button>
      )}
    </td>
  );
}

export interface EditableColumnsType<T> extends ColumnType<T> {
  editable?: boolean;
  rules?: Rule[];
  editableCellCss?: string;
  align?: 'left' | 'right' | 'center';
}

interface EditableTableProps<T> extends TableProps<T> {
  editableColumns: EditableColumnsType<T>[];
  onEdit: (row: T, columnName: keyof T) => void;
}

type Props<T> = Omit<EditableTableProps<T>, 'columns'>;

export default function EditableTable<T extends object>(props: Props<T>) {
  const { editableColumns, onEdit } = props;
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell<T>,
    },
  };

  const columns = editableColumns.map((col) => {
    if (col.editable !== true) return col;
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        ...col,
        title: col.title?.toString(),
        handleSave: onEdit,
      }),
    };
  });

  return <Table<T> {...props} components={components} columns={columns} />;
}
