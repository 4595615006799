import { selector } from 'recoil';

import { getMembers } from '../../helpers/members';
import { memberAtom } from '../atoms/member';

export const memberSelector = selector({
  key: 'memberSelector',
  get: async () => {
    try {
      return getMembers();
    } catch (err) {
      return [];
    }
  },
  set: ({ set }, newValue) => {
    set(memberAtom, newValue);
  },
});
