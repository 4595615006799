import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { SYSTEM_HIGHT } from '../../../constants/common';
import { ProjectDetailOutput } from '../../../domain/cost-expense';
import { getProjectDetail } from '../../../helpers/cost-expense';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import CostExpenseDetailSearch from './DetailSearch';
import CostExpenseDetailTable from './DetailTable';

function CostExpenseDetail() {
  const location = useLocation();
  const [showErrorNotification] = useErrorNotification();
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);

  const [, , jobNo] = location.pathname.split('/');

  const [projectDetail, setProjectDetail] = useState<ProjectDetailOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const searchHeight = showSearch ? 90 + SYSTEM_HIGHT.BREADCRUMBS : 46;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getProjectDetail(jobNo);
        setProjectDetail(res);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  return (
    <>
      {jobNo != null && (
        <CostExpenseDetailSearch
          onResize={handleSearchResize}
          jobNo={jobNo}
          projectTitle={
            projectDetail.length !== 0
              ? projectDetail[0].projectTitle ?? ''
              : ''
          }
        />
      )}
      <CostExpenseDetailTable
        costExpenseDetail={projectDetail}
        loading={loading}
        searchHeight={searchHeight}
      />
    </>
  );
}

export default CostExpenseDetail;
