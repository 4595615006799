import { useState } from 'react';

import { EditEmployeeCost } from '../../../domain/manage-cost';
import { table, uniqueByColumn } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { DeleteButton, EditableTable, ReflectButton } from '../../common';
import { EditableColumnsType } from '../../common/EditableTable';
import { getColumnSearchProps } from '../../common/TableFilter';

interface Props {
  employeeList: EditEmployeeCost[];
  loading?: boolean;
  onEdit: (row: EditEmployeeCost) => void;
  onSave: () => Promise<void>;
  onDelete: (rows: EditEmployeeCost[]) => Promise<void>;
}

const TABLE_HEADER_HEIGHT = 40;
const TABLE_TOOLBAR_HEIGHT = 44;

export default function EmployeeEditTable({
  employeeList,
  loading = false,
  onEdit,
  onSave,
  onDelete,
}: Props) {
  const columnSettings: EditableColumnsType<EditEmployeeCost>[] = [
    {
      title: '変更',
      dataIndex: 'edited',
      width: 50,
      render: (value: boolean) => (value ? '*' : ''),
      align: 'center',
      fixed: 'left',
    },
    {
      title: '本部名',
      dataIndex: 'hqName',
      defaultSortOrder: 'descend',
      sorter: (a: EditEmployeeCost, b: EditEmployeeCost) =>
        table.descendingComparator(a, b, 'hqName'),
      ...getColumnSearchProps(
        'hqName',
        '本部名',
        uniqueByColumn(employeeList, 'hqName').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: '部署名',
      dataIndex: 'deptName',
      sorter: (a, b) => table.descendingComparator(a, b, 'deptName'),
      ...getColumnSearchProps(
        'deptName',
        '部署名',
        uniqueByColumn(employeeList, 'deptName').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: 'グループ名',
      dataIndex: 'groupName',
      sorter: (a, b) => table.descendingComparator(a, b, 'groupName'),
      ...getColumnSearchProps(
        'groupName',
        'グループ名',
        uniqueByColumn(employeeList, 'groupName').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: '役職',
      dataIndex: 'jobPosition',
      sorter: (a, b) => table.descendingComparator(a, b, 'jobPosition'),
      ...getColumnSearchProps(
        'jobPosition',
        '役職',
        uniqueByColumn(employeeList, 'jobPosition').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: 'ゾーン',
      dataIndex: 'zone',
      sorter: (a, b) => table.descendingComparator(a, b, 'zone'),
      ...getColumnSearchProps(
        'zone',
        'ゾーン',
        uniqueByColumn(employeeList, 'zone').flatMap((val) => {
          return val != null ? { text: val, value: val } : [];
        })
      ),
      align: 'center',
    },
    {
      title: '標準原価',
      dataIndex: 'stdCostAmnt',
      align: 'right',
      editable: true,
      rules: [
        {
          required: true,
          message: '標準原価を入力してください',
        },
        {
          pattern: /^[0-9]+$/,
          message: '数字で入力してください',
        },
      ],
      editableCellCss: classes.editableCellNumberValue,
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleDelete = async () => {
    const employee = employeeList.filter((emp) =>
      selectedRowKeys.find((key) => key === emp.key)
    );
    if (employee === undefined) throw new Error('Employee not found');
    setSelectedRowKeys([]);
    onDelete(employee);
  };

  return (
    <EditableTable<EditEmployeeCost>
      editableColumns={columnSettings}
      rowKey="key"
      dataSource={employeeList}
      loading={loading}
      onEdit={onEdit}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
          setSelectedRowKeys(newSelectedRowKeys);
        },
      }}
      heightSize={TABLE_HEADER_HEIGHT + TABLE_TOOLBAR_HEIGHT}
      toolBar={
        <div className={classes.tableToolbar}>
          <ReflectButton
            disabled={
              employeeList.filter((employee) => employee.edited).length === 0
            }
            onConfirm={onSave}
          >
            データを反映
          </ReflectButton>
          <DeleteButton
            onConfirm={handleDelete}
            disabled={selectedRowKeys.length === 0}
          />
        </div>
      }
    />
  );
}
