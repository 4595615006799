import { SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, InputNumber, Space } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useState } from 'react';

import classes from '../../styles/search.module.scss';

export function TableNumberFilterProps<T>(dataIndex: keyof T): ColumnType<T> {
  const [minValue, setMinValue] = useState<number | string | null>(null);
  const [maxValue, setMaxValue] = useState<number | string | null>(null);
  const [includeZero, setIncludeZero] = useState<boolean>(false);

  return {
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Form.Item label="上限" className={classes.searchNumberFilter}>
          <InputNumber
            placeholder="指定した値以下を表示"
            value={maxValue}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => (value ? value.replace(/(,*)/g, '') : '')}
            tabIndex={-1}
            onChange={(val) => {
              setMaxValue(val != null ? Number(val) : null);
              setSelectedKeys([maxValue || 0, minValue || 0]);
            }}
            className={classes.searchNumberFilterField}
          />
        </Form.Item>
        <Form.Item label="下限" className={classes.searchNumberFilter}>
          <InputNumber
            placeholder="指定した値以上を表示"
            value={minValue}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => (value ? value.replace(/(,*)/g, '') : '')}
            tabIndex={0}
            onChange={(val) => {
              setMinValue(val != null ? Number(val) : null);
              setSelectedKeys([maxValue || 0, minValue || 0]);
            }}
            className={classes.searchNumberFilterField}
          />
        </Form.Item>
        <Form.Item className={classes.searchNumberFilter}>
          <Checkbox
            onChange={(e) => {
              setSelectedKeys([maxValue || 0, minValue || 0]);
              setIncludeZero(e.target.checked);
            }}
          >
            0は含まない
          </Checkbox>
        </Form.Item>
        <Divider style={{ margin: '8px 0' }} />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys([maxValue || 0, minValue || 0]);
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            検索
          </Button>
          <Button
            onClick={() => {
              setMinValue(null);
              setMaxValue(null);
              setIncludeZero(false);

              if (clearFilters !== undefined) clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            リセット
          </Button>
        </Space>
      </div>
    ),
    filterSearch: true,
    onFilter: (value, record) => {
      const data = Number(record[dataIndex]);
      return (
        (includeZero === false || data !== 0) &&
        (minValue !== null ? data >= Number(minValue) : true) &&
        (maxValue !== null ? data <= Number(maxValue) : true)
      );
    },
  };
}
