import camelcaseKeys from 'camelcase-keys';

import { api } from '../lib';
import { UploadFileTypeEnum } from '../server/api';

const uploadBpWorksFile = async (uploadFile: File) => {
  return api.uploadApi.postFile('bp-works', uploadFile);
};

const uploadAccountFile = async (uploadFile: File) => {
  return api.uploadApi.postFile('account', uploadFile);
};

const getUploadFilesList = async (
  fileType: UploadFileTypeEnum,
  fromDate: string,
  toDate: string
) => {
  const res = await api.uploadApi.getBpWorksFilesList(
    fileType,
    fromDate,
    toDate
  );
  const fileStatus = {
    0: 'upload_wait',
    1: 'uploading',
    2: 'uploaded',
    3: 'upload_complete',
    4: 'upload_failed',
  };

  const filesList = camelcaseKeys(res.data, { deep: true }).map((list) => ({
    ...list,
    updateDate: new Date(list.updateDate).toLocaleString(),
    fileStatus: fileStatus[list.fileStatus],
  }));
  return filesList;
};

export { uploadBpWorksFile, uploadAccountFile, getUploadFilesList };
