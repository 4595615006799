import { useEffect, useState } from 'react';

import { Member } from '../../../domain/users';
import { getMemberList } from '../../../helpers/users';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import UserEditTable from './UserEditTable';

export function UserEdit() {
  useCheckUserPermission([RoleEnum.Admin]);
  const [showErrorNotification] = useErrorNotification();
  const [userInfoList, setUserInfoList] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);

  const loadMemberList = async (includeLeave: boolean) => {
    setLoading(true);
    try {
      const res = await getMemberList(true);
      setUserInfoList(
        includeLeave ? res : res.filter((member) => !member.isDeleted)
      );
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => loadMemberList(false))();
  }, []);

  const handleSubmit = (users: Member[], includeLeave: boolean) => {
    setUserInfoList(
      includeLeave ? users : users.filter((user) => !user.isDeleted)
    );
  };

  const handleDelete = (empCode: string) => {
    setUserInfoList(userInfoList.filter((user) => user.empCode !== empCode));
  };

  const handleLeaveChange = async (checked: boolean) => {
    await loadMemberList(checked);
  };

  return (
    <UserEditTable
      loginUserList={userInfoList}
      loading={loading}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onLeaveChange={handleLeaveChange}
    />
  );
}
