import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { ProjectList } from '../../../domain/cost-expense';
import { getProjectList } from '../../../helpers/cost-expense';
import {
  fromYearMonthState,
  toYearMonthState,
} from '../../../recoil/atoms/cost-expense';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import CostExpenseListSearch from './ListSearch';
import CostExpenseListTable from './ListTable';

function CostExpenseList() {
  const [showErrorNotification] = useErrorNotification();
  const fromYearMonth = useRecoilValue(fromYearMonthState);
  const toYearMonth = useRecoilValue(toYearMonthState);
  const [projectList, setProjectList] = useState<ProjectList[]>([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const searchHeight = showSearch ? 94 : 46;
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const cost = await getProjectList(
          fromYearMonth.toDate(),
          toYearMonth.toDate()
        );
        setProjectList(cost);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, [fromYearMonth, toYearMonth]);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  return (
    <>
      <CostExpenseListSearch onResize={handleSearchResize} />
      <CostExpenseListTable
        costExpenseList={projectList}
        searchHeight={searchHeight}
        loading={loading}
      />
    </>
  );
}

export default CostExpenseList;
