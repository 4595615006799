import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';

import { ProjectDetailOutput } from '../../../domain/cost-expense';
import { table } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import { Table } from '../../common';

const TABLE_HEADER_HEIGHT = 62;

const columns: ColumnsType<ProjectDetailOutput> = [
  {
    title: '年月',
    dataIndex: 'yearMonth',
    key: 'yearMonth',
    width: 100,
    align: 'center',
    sorter: (a, b) => table.descendingComparator(a, b, 'yearMonth'),
    defaultSortOrder: 'ascend',
    render: (_, { yearMonth, workYear, workMonth, jobNo }) => (
      <Link to={`/cost-expense/${jobNo}/${yearMonth}`}>
        {workYear}/{workMonth.toString().padStart(2, '0')}
      </Link>
    ),
  },
  {
    title: '予定原価\n(円)',
    dataIndex: 'planCostAmnt',
    width: 130,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'planCostAmnt'),
  },
  {
    title: '実績原価\n(円)',
    dataIndex: 'costAmnt',
    width: 130,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'costAmnt'),
  },
  {
    title: '原価予測\n(円)',
    dataIndex: 'forecastCostAmnt',
    width: 130,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'forecastCostAmnt'),
  },
  {
    title: '予定工数\n(時間)',
    dataIndex: 'planManHour',
    width: 110,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
  },
  {
    title: '実績工数\n(時間)',
    dataIndex: 'manHour',
    width: 110,
    align: 'right',
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
  },
  {
    title: '工数\n進捗率',
    dataIndex: 'manHourProgressRate',
    width: 110,
    align: 'right',
    onCell: ({ manHourProgressRate }) => ({
      className: (manHourProgressRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
    render: (value: number) => `${value ?? '0.00'} %`,
    sorter: (a, b) => table.descendingComparator(a, b, 'manHourProgressRate'),
  },
  {
    title: '工数予測\n(時間)',
    dataIndex: 'forecastManHour',
    width: 110,
    align: 'right',
    onCell: ({ forecastManHour, planManHour }) => ({
      className:
        (forecastManHour ?? 0) > (planManHour ?? 0) ? classes.dangerColor : '',
    }),
    render: (value: number) => value?.toLocaleString(),
    sorter: (a, b) => table.descendingComparator(a, b, 'forecastManHour'),
  },
  {
    title: '工数消化率\n(予測)',
    dataIndex: 'forecastManHourUseRate',
    width: 120,
    align: 'right',
    onCell: ({ forecastManHourUseRate }) => ({
      className: (forecastManHourUseRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
    render: (value: number) => `${value ?? '0.00'} %`,
    sorter: (a, b) =>
      table.descendingComparator(a, b, 'forecastManHourUseRate'),
  },
  {
    title: '更新日',
    dataIndex: 'updateDate',
    sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    width: 100,
  },
];

interface Props {
  costExpenseDetail: ProjectDetailOutput[];
  searchHeight: number;
  loading?: boolean;
}

export default function CostExpenseDetailTable({
  costExpenseDetail,
  searchHeight,
  loading = false,
}: Props) {
  return (
    <Table<ProjectDetailOutput>
      rowKey="yearMonth"
      dataSource={costExpenseDetail}
      columns={columns}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
