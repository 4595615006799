import { ColumnsType } from 'antd/es/table';

import { PersonalSummaryOutput } from '../../../domain/man-hours';
import { table } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import Table from '../../common/Table';

import { detailLink } from './ManHoursCommon';

interface Props {
  personalMh: PersonalSummaryOutput[];
  searchHeight: number;
  loading: boolean;
}

const TABLE_HEADER_HEIGHT = 62;

const columns: ColumnsType<PersonalSummaryOutput> = [
  {
    title: '年月',
    dataIndex: 'yearMonth',
    width: 70,
    align: 'center',
    sorter: (a, b) => table.descendingComparator(a, b, 'yearMonth'),
  },
  {
    title: '予定工数\n(時間)',
    dataIndex: 'planManHour',
    width: 100,
    align: 'right',
    sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
  },
  {
    title: '実績工数\n(時間)',
    dataIndex: 'manHour',
    width: 100,
    align: 'right',
    sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
  },
  {
    title: '稼働率',
    dataIndex: 'operationRate',
    width: 100,
    align: 'right',
    render: (value: number) => `${value ?? '0.00'} %`,
    sorter: (a, b) => table.descendingComparator(a, b, 'operationRate'),
    onCell: ({ operationRate }) => ({
      className: (operationRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
  },
  {
    title: '更新日',
    dataIndex: 'updateDate',
    align: 'center',
    width: 100,
    sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
  },
  {
    title: '',
    width: 175,
    render: (_text, record) => (
      <>{detailLink(record.workYear, record.workMonth, record.empCode)}</>
    ),
  },
];

export default function ManHoursTotalPersonalTable({
  personalMh,
  searchHeight,
  loading,
}: Props) {
  return (
    <Table<PersonalSummaryOutput>
      rowKey={(record) => `${record.workYear}${record.workMonth}`}
      dataSource={personalMh}
      columns={columns}
      loading={loading}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
    />
  );
}
