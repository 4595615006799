import { Checkbox, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { ROLE } from '../../../constants/common';
import { Member } from '../../../domain/users';
import { deleteMember } from '../../../helpers/users';
import { table, uniqueByColumn } from '../../../lib';
import { RoleEnum } from '../../../server';
import classes from '../../../styles/table.module.scss';
import { DeleteButton, Table } from '../../common';
import { getColumnSearchProps } from '../../common/TableFilter';

import UserEditModalBtn from './UserEditModalBtn';

const TABLE_HEADER_HEIGHT = 40;
const TABLE_TOOLBAR_HEIGHT = 44;

interface Props {
  loginUserList: Member[];
  loading?: boolean;
  onSubmit: (row: Member[], includeLeave: boolean) => void;
  onDelete: (userId: string) => void;
  onLeaveChange: (checked: boolean) => void;
}

export default function UserEditTable({
  loginUserList,
  onSubmit,
  loading,
  onDelete,
  onLeaveChange,
}: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [checked, setChecked] = useState<boolean>(false);

  const handleRowAdd = (users: Member[]) => {
    onSubmit(users, checked);
  };

  const handleRowEdit = (users: Member[]) => {
    onSubmit(users, checked);
    setSelectedRowKeys([]);
  };

  const handleDelete = async () => {
    const userInfo = loginUserList.find(
      (user) => user.empCode === selectedRowKeys[0]
    );
    if (userInfo === undefined) throw new Error('User not found');
    const { empCode } = userInfo;
    await deleteMember(empCode);
    setSelectedRowKeys([]);
    onDelete(empCode);
  };

  const columns: ColumnsType<Member> = [
    {
      title: '社員番号',
      dataIndex: 'empCode',
      defaultSortOrder: 'descend',
      sorter: (a, b) => table.descendingComparator(a, b, 'empCode'),
      ...getColumnSearchProps(
        'empCode',
        '社員番号',
        (uniqueByColumn<Member>(loginUserList, 'empCode') as string[]).flatMap(
          (val) => {
            return val !== undefined ? { text: val, value: val } : [];
          }
        )
      ),
      width: 120,
    },
    {
      title: '氏名',
      dataIndex: 'empName',
      sorter: (a, b) => table.descendingComparator(a, b, 'empName'),
      ...getColumnSearchProps(
        'empName',
        '氏名',
        (uniqueByColumn<Member>(loginUserList, 'empName') as string[]).flatMap(
          (val) => {
            return val !== undefined ? { text: val, value: val } : [];
          }
        )
      ),
      width: 150,
    },
    {
      title: 'ロール',
      dataIndex: 'role',
      sorter: (a, b) => table.descendingComparator(a, b, 'role'),
      render: (_, { role }) => {
        const settings =
          role != null && role !== RoleEnum.NoPermission ? ROLE[role] : null;
        return settings ? (
          <Tag color={settings.color}>{settings.name}</Tag>
        ) : null;
      },
      width: 110,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      sorter: (a, b) => table.descendingComparator(a, b, 'email'),
      width: 150,
    },
    {
      title: '退職日',
      dataIndex: 'leaveDate',
      align: 'center',
      sorter: (a, b) => table.descendingComparator(a, b, 'leaveDate'),
      render: (_, { leaveDate }) =>
        leaveDate != null ? dayjs(leaveDate).format('YYYY/MM/DD') : null,
      width: 100,
    },
    {
      title: '作成日',
      dataIndex: 'createDate',
      width: 150,
      sorter: (a, b) => table.descendingComparator(a, b, 'createDate'),
    },
    {
      title: '更新日',
      dataIndex: 'updateDate',
      width: 150,
      sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
    },
  ];

  return (
    <Table<Member>
      dataSource={loginUserList}
      columns={columns}
      rowKey="empCode"
      loading={loading}
      heightSize={TABLE_HEADER_HEIGHT + TABLE_TOOLBAR_HEIGHT}
      rowClassName={(record) => (record.isDeleted ? classes.deletedRow : '')}
      rowSelection={{
        type: 'radio',
        selectedRowKeys,
        onChange: (newSelectedRowKeys: React.Key[]) => {
          setSelectedRowKeys(newSelectedRowKeys);
        },
        getCheckboxProps: (record) => ({
          disabled: record.isDeleted,
        }),
      }}
      toolBar={
        <div className={classes.tableToolbar}>
          <UserEditModalBtn
            title="新規追加"
            okButtonProps={{ type: 'primary' }}
            onSubmit={handleRowAdd}
          />
          <Checkbox
            className={classes.leaveCheckBox}
            onChange={(e) => {
              onLeaveChange(e.target.checked);
              setChecked(e.target.checked);
            }}
          >
            退職者も表示
          </Checkbox>
          <UserEditModalBtn
            title="編集"
            okButtonProps={{
              name: 'edit',
              disabled: selectedRowKeys.length !== 1,
            }}
            userInfo={loginUserList.find(
              (user) => selectedRowKeys[0] === user.empCode
            )}
            onSubmit={handleRowEdit}
          />
          <DeleteButton
            onConfirm={handleDelete}
            disabled={selectedRowKeys.length === 0}
          />
        </div>
      }
    />
  );
}
