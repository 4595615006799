export const SYSTEM_HIGHT = {
  MAIN_PADDING: 10,
  PAGINATION: 34,
  TABLE_PADDING: 24,
  BREADCRUMBS: 34,
  SCROLL_BAR: 10,
};

export const ROLE = {
  normal: { name: '一般', color: 'blue' },
  head: { name: '本部', color: 'green' },
  admin: { name: '管理者', color: 'magenta' },
} as const;

export const MONTH = [
  { value: '', label: '' },
  { value: '1', label: '1月' },
  { value: '2', label: '2月' },
  { value: '3', label: '3月' },
  { value: '4', label: '4月' },
  { value: '5', label: '5月' },
  { value: '6', label: '6月' },
  { value: '7', label: '7月' },
  { value: '8', label: '8月' },
  { value: '9', label: '9月' },
  { value: '10', label: '10月' },
  { value: '11', label: '11月' },
  { value: '12', label: '12月' },
];

export type ROLE_TYPE = keyof typeof ROLE;
