import { useEffect, useState } from 'react';

import {
  EditEmployeeCost,
  SaveEmployeeCost,
} from '../../../domain/manage-cost';
import {
  deleteEmployeeList,
  getEmployeeList,
  saveEmployeeList,
} from '../../../helpers/manage-cost';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import EmployeeEditTable from './EmployeeEditTable';

export default function EmployeeEdit() {
  const [showErrorNotification] = useErrorNotification();
  useCheckUserPermission([RoleEnum.Admin]);
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState<EditEmployeeCost[]>([]);

  const loadEmployeeList = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeList();
      setEmployeeList(res);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => loadEmployeeList())();
  }, []);

  const handleEdit = (row: EditEmployeeCost) => {
    const list = employeeList.map((employee) => ({
      ...employee,
      stdCostAmnt:
        employee.key === row.key ? row.stdCostAmnt : employee.stdCostAmnt,
      edited: employee.edited === true || employee.key === row.key,
    }));
    setEmployeeList(list);
  };

  const handleSave = async () => {
    const editList = employeeList.filter(
      (employee) => employee.edited === true
    );

    try {
      const res = await saveEmployeeList(editList as SaveEmployeeCost[]);
      setEmployeeList(res);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    }
  };

  const handleDelete = async (rows: EditEmployeeCost[]) => {
    try {
      const results = await deleteEmployeeList(rows);
      setEmployeeList(results);
    } catch (err) {
      if (err instanceof Error) showErrorNotification(err);
      throw err;
    }
  };

  return (
    <EmployeeEditTable
      employeeList={employeeList}
      loading={loading}
      onEdit={handleEdit}
      onSave={handleSave}
      onDelete={handleDelete}
    />
  );
}
