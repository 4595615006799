import { ColumnsType } from 'antd/es/table';

import { PersonalProject } from '../../../domain/man-hours';
import { table } from '../../../lib';
import classes from '../../../styles/table.module.scss';
import Table from '../../common/Table';

const TABLE_HEADER_HEIGHT = 62;

const columns: ColumnsType<PersonalProject> = [
  {
    title: 'PJ番号',
    dataIndex: 'projectCode',
    width: 80,
    align: 'center',
    sorter: (a, b) => table.descendingComparator(a, b, 'projectCode'),
  },
  {
    title: 'プロジェクト名',
    dataIndex: 'projectName',
    width: 200,
    sorter: (a, b) => table.descendingComparator(a, b, 'projectName'),
  },
  {
    title: 'JOBNo',
    dataIndex: 'jobNo',
    width: 100,
    sorter: (a, b) => table.descendingComparator(a, b, 'jobNo'),
  },
  {
    title: '案件名',
    dataIndex: 'projectTitle',
    width: 300,
    sorter: (a, b) => table.descendingComparator(a, b, 'projectTitle'),
  },
  {
    title: '予定工数\n(時間)',
    dataIndex: 'planManHour',
    width: 100,
    align: 'right',
    sorter: (a, b) => table.descendingComparator(a, b, 'planManHour'),
  },
  {
    title: '実績工数\n(時間)',
    dataIndex: 'manHour',
    width: 100,
    align: 'right',
    sorter: (a, b) => table.descendingComparator(a, b, 'manHour'),
  },
  {
    title: '稼働率',
    dataIndex: 'operationRate',
    width: 90,
    align: 'right',
    sorter: (a, b) => table.descendingComparator(a, b, 'operationRate'),
    render: (value: number) => `${value ?? '0.00'} %`,
    onCell: ({ operationRate }) => ({
      className: (operationRate ?? 0) > 100 ? classes.dangerColor : '',
    }),
  },
  {
    title: '更新日',
    dataIndex: 'updateDate',
    width: 100,
    sorter: (a, b) => table.descendingComparator(a, b, 'updateDate'),
  },
];

interface Props {
  personalPjDetailList: PersonalProject[];
  searchHeight: number;
  loading: boolean;
}

export default function ManHoursProjectsDetailTable({
  personalPjDetailList,
  searchHeight,
  loading = false,
}: Props) {
  return (
    <Table<PersonalProject>
      rowKey="jobNo"
      dataSource={personalPjDetailList}
      columns={columns}
      heightSize={searchHeight + TABLE_HEADER_HEIGHT}
      loading={loading}
    />
  );
}
