import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SYSTEM_HIGHT } from '../../../constants/common';
import { ProjectMonthlyDetail } from '../../../domain/cost-expense';
import { getMonthDetail } from '../../../helpers/cost-expense';
import { RoleEnum } from '../../../server';
import { useErrorNotification } from '../../hooks/error-notification';
import { useCheckUserPermission } from '../../hooks/user-permission';

import MonthDetailSearch from './MonthDetailSearch';
import MonthDetailTable from './MonthDetailTable';

export default function MonthDetail() {
  const location = useLocation();
  const [showErrorNotification] = useErrorNotification();
  useCheckUserPermission([RoleEnum.Admin, RoleEnum.Head, RoleEnum.Normal]);
  const [, , jobNo, yearMonth] = location.pathname.split('/');

  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [monthDetail, setMonthDetail] = useState<ProjectMonthlyDetail[]>([]);
  const searchHeight = showSearch ? 134 + SYSTEM_HIGHT.BREADCRUMBS : 46;

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getMonthDetail(jobNo, yearMonth);
        setMonthDetail(res);
      } catch (err) {
        if (err instanceof Error) showErrorNotification(err);
        throw err;
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSearchResize = async () => {
    setShowSearch(showSearch === false);
  };

  return (
    <>
      <MonthDetailSearch
        jobNo={jobNo}
        yearMonth={yearMonth}
        projectTitle={
          monthDetail.length > 0 ? monthDetail[0].projectTitle ?? '' : ''
        }
        onResize={handleSearchResize}
      />
      <MonthDetailTable
        monthDetail={monthDetail}
        loading={loading}
        heightSize={searchHeight}
      />
    </>
  );
}
